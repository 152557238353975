import { BigContentCard } from "./BigContentCard";
import { Center, Box, useBreakpointValue } from "@chakra-ui/react";

export function ProjectContent({ project }) {
  const variant = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg" })
  return (
    <Center>
      <Box w={["95vw", "90vw", "75vw", "60vw"]} textAlign={variant === "base" && "justify"} style={{ hyphens: "auto" }} maxW={1100} fontSize={18}>
        {project.text}
      </Box>
    </Center>
  );
}
