import { HStack, Stack, Center, Text } from "@chakra-ui/react";
import { BigProjectPreview } from "./BigProjectPreview";
import { projects } from "./static-variables/projects";

export function ShowAllProjects({ type }) {
  console.warn("Type : ", type)
  return (
    <Center pt={10}>
      <Stack spacing={20}>
        {projects.map((p, i) => {
          if (p.type === type) {
            return (
              <BigProjectPreview
                project={p}
                key={i}
                text={
                  <Text>
                    <Text fontWeight="bold" fontSize={27}>
                      {p.name}
                    </Text>
                    <br />
                    <Text noOfLines={5}>
                      {p.text}
                    </Text>
                  </Text>
                }
                imageSize={"30vw"}
                number={2}
              />
            );
          }
        })}
      </Stack>
    </Center>
  );
}
