import {
  Text,
  Spacer,
  HStack,
  Divider,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { useNavigate, Link } from "react-router-dom";

export function Footer(props) {
  const { ...rest } = props;
  const navigate = useNavigate();
  const variant = useBreakpointValue({ base: "base", sm: "sm" });

  return (
    <HStack w={"100%"} {...rest}>
      <a href="/datenschutz">
        <Text
          color={"gray.500"}
          cursor={"pointer"}
          onClick={() => navigate("/datenschutz")}
        >
          {" "}
          Datenschutz
        </Text>
      </a>
      <a href="/impressum">
        <Text color={"gray.500"} cursor={"pointer"} onClick={() => navigate("/impressum")}>
          {" "}
          Impressum
        </Text>
      </a>
      <Spacer />
      {variant !== "base" && (
        <HStack fontWeight={"bold"} spacing={5}>
          <Text> Tel +49 (0)8152-794802 </Text>
          <Text>
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "mailto:info@moebius-architekten.de";
                e.preventDefault();
              }}
            >
              info@moebius-architekten.de
            </Link>
          </Text>
          <Text> Madeleine-Ruoff-Str. 44, 82211 Herrsching </Text>
          <Spacer />
        </HStack>
      )}

    </HStack>
  );
}
