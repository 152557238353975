import { Text } from "@chakra-ui/react"
import headerImage from './06-OE-2012-01-moebius-architekt-oeffentliche Baumassnahme-Hotel-Garmisch-Umbau.webp'

export const _06_OE_2012 = () => {
    //06-OE-2012-01-moebius-architekt-oeffentliche Baumassnahme-Hotel-Garmisch-Umbau
    const projectID = "06-OE-2012"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Hotel-Garmisch-Umbau"
    const name = "Hotel - Garmisch"
    const notification = false
    const text = <Text>
        Das Hotel der Bundeswehr für Gäste anderer Nationen entsprach nicht mehr dem heutigen Standard.
        <br />
        Im Rahmen der Baumaßnahme wurde das gesamte Gebäude im Inneren modernisiert und neu konzipiert. Hierbei wurden neben der Neugestaltung der Zimmer und Flure vor allem der Brand- und Schallschutz an die geltenden Standards angepasst.
        <br />
        <br />
        Hotel Garmisch | Umbau | LPH 1-8 | 2011-2012
    </Text>
    const city = "Garmisch"
    const country = "Deutschland"
    const imageLength = 4

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./06-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: headerImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
