import { Text } from "@chakra-ui/react"

export const _07_K_2004 = () => {
    //07-K-2004-01-moebius-architekt-Wohnsiedlung-Augsburg-Konzept
    const projectID = "07-K-2004"
    const projectName = "moebius-architekt-Wohnsiedlung-Augsburg-Konzept"
    const name = "Konzept Wohnsiedlung - Welden"
    const notification = false
    const text = <Text>
        Direkt am Naturpark Augsburg sollten günstige Einfamilienhäuser mit guten Sichtbeziehungen entstehen. Ein bestehender Bebauungsplan musste hierfür überarbeitet werden.
        <br />
        Entwickelt wurden lange aufgeständerte Baukörper, die leicht gedreht zum Hang stehen, um einen freien Blick zu gewährleisten und die Sonnenenergie optimal nutzen zu können.
        Die geringe Gebäudetiefe und die vollverglaste Südfront minimieren den Energiebedarf.
        <br />
        Im Norden des eingeschossigen Gebäudes liegt ein Lagerraum, so dass auf Unterkellerung verzichtet werden kann. Hierdurch und aufgrund der geplanten Holzrahmen-Fertigbauweise liegen trotz gehobenen Standards die Baukosten auf unter 300.000 €.
        <br />
        <br />
        Siedlung Welden | Konzept Bebauung | 2004
    </Text>
    const city = "Welden"
    const country = "Deutschland"
    const imageLength = 2

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./07-K-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
