import { Text } from "@chakra-ui/react"

export const _10_OE_2011 = () => {
    //10-OE-2011-01-moebius-architekt-oeffentliche Baumassnahme-Krankenhaus-Fuerstenfeldbruck-Umbau
    const projectID = "10-OE-2011"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Krankenhaus-Fuerstenfeldbruck-Umbau"
    const name = "Sanitätszentrum - Fürstenfeldbruck"
    const notification = false
    const text = <Text>
        Aufgrund von erheblichen Schäden in der Baukonstruktion musste die Hülle saniert werden. Dabei wurden die Fassade, die Fenster, der Sonnenschutz und das Dach komplett saniert bzw. erneuert.
        <br />
        Weiter wurden die Flucht- und Rettungswege angepasst, die Behandlungsräume klimatisiert und eine überdachte Zufahrt für den Liegendtransport eingerichtet.
        <br />
        <br />
        Sanitätsgebäude - Fürstenfeldbruck | Sanierung | LPH 1-8 | 2011
    </Text>
    const city = "Fürstenfeldbruck"
    const country = "Deutschland"
    const imageLength = 2

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./10-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
