import { Text } from '@chakra-ui/react'
import { _01_OE_2017 } from '../data/Oeffentlich/01-OE-2017-WOLF-Vermessungsamt/01-OE-2017-WOLF-Vermessungsamt';
import { _02_OE_2013 } from '../data/Oeffentlich/02-OE-2013-Veranstaltungszentrum-Garmisch/02-OE-2013-Veranstaltungszentrum-Garmisch';
import { _03_OE_2011 } from '../data/Oeffentlich/03-OE-2011-Flugzeugwartungshalle-Bayern/03-OE-2011-Flugzeugwartungshalle-Bayern';
import { _04_OE_2009 } from '../data/Oeffentlich/04-OE-2009-Wasserturm-Lagerlechfeld/04-OE-2009-Wasserturm-Lagerlechfeld';
import { _06_OE_2012 } from '../data/Oeffentlich/06-OE-2012-Hotel-Garmisch/06-OE-2012-Hotel-Garmisch';
import { _08_OE_2009 } from '../data/Oeffentlich/08-OE-2009-Wohngebaeude-Lagerlechfeld/08-OE-2009-Wohngebaeude-Lagerlechfeld';
import { _01_W_2022 } from '../data/Wohnen/01-W-2022 Reihenendhaus - Herrsching/01-W-2022-Reihenendhaus-Herrsching';
import { _02_W_2019 } from '../data/Wohnen/02-W-2019-Wohnhaus-Muenchen/02-W-2019-Wohnhaus-Muenchen';
import { _03_W_2014 } from '../data/Wohnen/03-W-2014-Mehrfamilienhaus-Muenchen-Steinheil/03-W-2014-Mehrfamilienhaus-Muenchen-Steinheil';
import { _04_W_2014 } from '../data/Wohnen/04-W-2014-Mehrfamilienhaus-Muenchen-Altheimer-Eck/04-W-2014-Mehrfamilienhaus-Muenchen-Altheimer-Eck';
import { _05_W_2012 } from '../data/Wohnen/05-W-2012-Wohnen-in-den-Bergen–Kreuth/05-W-2012-Möbius-Architekt-Einfamilienhaus-Tegernsee-Neubau';
import { _06_W_2008 } from '../data/Wohnen/06-W-2008-Dachgeschossausbau–Muenchen/06-W-2008-Dachgeschossausbau–Muenchen';
import { _07_W_2007 } from '../data/Wohnen/07-W-2007-Erweiterung-Einfamilienhaus–Steinebach/07-W-2007-Erweiterung-Einfamilienhaus–Steinebach';
import { _08_W_2007 } from '../data/Wohnen/08-W-2007-Umbau-Einfamilienhaus–Muenchen/08-W-2007-Umbau-Einfamilienhaus–Muenchen';
import { _09_W_2006 } from '../data/Wohnen/09-W-2006-Wohnhaus-Madagaskar/09-W-2006-Wohnhaus-Madagaskar';
import { _10_W_2007 } from '../data/Wohnen/10-W-2007-Wohnsitz-des-Praesidenten-Madagaskar/10-W-2007-Wohnsitz-des-Praesidenten-Madagaskar';
import { _07_OE_2001 } from '../data/Oeffentlich/07-OE-2001-Gerichtsgebauede-Augsburg/07-OE-2001-Gerichtsgebauede-Augsburg';
import { _09_OE_2010 } from '../data/Oeffentlich/09-OE-2010- Verwaltungsgebaeude-Kleinaitingen/09-OE-2010- Verwaltungsgebaeude-Kleinaitingen.js';
import { _10_OE_2011 } from '../data/Oeffentlich/10-OE-2011-Sanitaetsgebaeude-Fuerstenfeldbruck/10-OE-2011-Sanitaetsgebaeude-Fuerstenfeldbruck';
import { _02_K_2014 } from '../data/Konzepte/02-K-2014-Wohnungsbau - Muenchen/02-K-2014-Wohnungsbau - Muenchen';
import { _04_K_2002 } from '../data/Konzepte/04-K-2002-Metaphorische Treppe/04-K-2002-Metaphorische Treppe';
import { _05_K_2004 } from '../data/Konzepte/05-K-2004-Pfegeheim/05-K-2004-Pfegeheim';
import { _03_K_2010 } from '../data/Konzepte/03-K-2010-come together-Muenchen/03-K-2010-come together-Muenchen';
import { _06_K_2014 } from '../data/Konzepte/06-K-2002-Dachaufbau-Muenchen/06-K-2002-Dachaufbau-Muenchen';
import { _07_K_2004 } from '../data/Konzepte/07-K-2004- Wohnsiedlung-Welden/07-K-2004- Wohnsiedlung-Welden';
import { _08_K_2004 } from '../data/Konzepte/08-K-2004-Pflegeheim-Horn/08-K-2004-Pflegeheim-Horn';
import { _09_K_2005 } from '../data/Konzepte/09-K-2005-Seniorenzentrum-Aichach/09-K-2005-Seniorenzentrum-Aichach';
import { _05_OE_2019 } from '../data/Oeffentlich/05-OE-2019-Umbau Nachbarschaftshilfe/05-OE-2019-Umbau Nachbarschaftshilfe';
import { _01_K_2014 } from '../data/Konzepte/01-K-2014-Rathaus-Seefeld/01-K-2014-Rathaus-Seefeld';

export const getProjects = () => {

  let newProjects = []
  for (let i = 0; i < projects.length; i++) {
    let newProject = projects[i]
    newProject.link = projects[i]?.link.toLowerCase().replace("möbius-architekt-", "").replace("moebius-architekt-", "")
    newProjects.push(newProject)
  }

  return newProjects
}

export const projects = [
  _01_W_2022(),
  _02_W_2019(),
  _03_W_2014(),
  _01_OE_2017(),
  _04_W_2014(),
  _05_W_2012(),
  _01_K_2014(),
  _06_W_2008(),
  _07_W_2007(),
  _08_W_2007(),
  _03_OE_2011(),
  _04_OE_2009(),
  _02_OE_2013(),
  _10_W_2007(),
  _09_W_2006(),
  _04_K_2002(),
  _06_K_2014(),

  _05_OE_2019(),
  _06_OE_2012(),
  _07_OE_2001(),
  _08_OE_2009(),
  _09_OE_2010(),
  _10_OE_2011(),

  _02_K_2014(),
  _03_K_2010(),
  _05_K_2004(),
  _07_K_2004(),
  _08_K_2004(),
  _09_K_2005(),
];
