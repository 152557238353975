import { Text } from "@chakra-ui/react"



export const _04_W_2014 = () => {
    //04-W-2011-01-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Umbau-Projektsteuerung
    const projectID = "04-W-2014"
    const projectName = "Möbius-Architekt-Mehrfamilienhaus-Muenchen-Umbau-Projektsteuerung"
    const name = "Umbau mit Anbau eines Mehrfamilienhauses in München"
    const notification = false
    const text = <Text>
        Das Grundstück befindet sich in unmittelbarer Nähe zum Marienplatz und ermöglicht aus den Obergeschossen fantastische Blicke auf Rathaus, Frauenkirche und die Berge.
        <br />
        Das bestehende Vorder- und Rückgebäude wurde aufgestockt und weitgehend entkernt, um den heutigen Anforderungen entsprechende Büro- und Wohnflächen zu schaffen.
        <br />
        Der Hof wurde mit einem Anbau ergänzt, um das Grundstück optimal auszunutzen.
        Um Parkplätze zu schaffen, musste das bestehende Vordergebäude um ein Geschoss nach unten ergänzt werden und kann nun mit einem Parklift erreicht werden.
        Bei dieser sehr komplexen Bauaufgabe wurde die Bauherrenvertretung und die Projektsteuerung übernommen.
        <br />
        <br />
        Mehrfamilienhaus München | Umbau und Aufstockung mit Anbau | Projektsteuerung | Ausführung 2013-2015
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 7

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./04-W-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "")}`,
        type: type,
    }
}
