import { Text } from "@chakra-ui/react"

export const _01_K_2014 = () => {
    //01-K-2014-05-moebius-architekt-Rathaus-Seefeld-Wettbewerb
    const projectID = "01-K-2014"
    const projectName = "moebius-architekt-Rathaus-Seefeld-Wettbewerb"
    const name = "Neubau Rathaus - Seefeld - Wettbewerb"
    const notification = false
    const text = <Text>
        Neubau Rathaus - Seefeld | Wettbewerb | 2014
        <br />
        <br />
    </Text>
    const city = "Seefeld"
    const country = "Deutschland"
    const imageLength = 9

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 5; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./01-K-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
