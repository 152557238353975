import { Text } from "@chakra-ui/react"

export const _05_OE_2019 = () => {
    //05-OE-2019-01-moebius-architekt-oeffentliche Baumassnahme-Umbau-Seefeld
    const projectID = "05-OE-2019"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Umbau-Seefeld"
    const name = "Umbau Scheune zu Nachbarschaftshilfe - Seefeld"
    const notification = false
    const text = <Text>
        Ein ehemaliger Bauernhof sollte mit geringen Mitteln zur Nachbarschaftshilfe mit Verkaufsräumen für Kleidung und Tagespflege umgebaut werden.
        <br />
        Wesentlicher Aspekt waren die Beachtung des Brandschutzes.
        <br />
        Das Gebäude wurde in seiner äußeren Form unverändert gelassen, das Scheuentor im 1.OG wurde zum Zugang zur Kleiderbörse gemacht.
        <br />
        <br />
        Nachbarschaftshilfe Seefeld | Umbau | LPH 1-8 | 2019
    </Text>
    const city = "Seefeld"
    const country = "Deutschland"
    const imageLength = 4

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./05-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
