import {
  Box,
  HStack,
  Center,
  Text,
  Image,
  Spacer,
  RadioGroup,
  Radio,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Footer } from "../components/Footer";
import { useState, useEffect, useContext } from "react";
import { StickyHeader } from "../components/sticky-header/StickyHeader";
import { useNavigate } from "react-router-dom";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { AppContext } from "../AppContext";

export function Galery() {

  const navigate = useNavigate();
  const [data, setImage] = useContext(AppContext);
  const [value, setValue] = useState(data?.id ? data?.id : 0);

  function changeValue(change) {
    if (value + change < 0) {
      setValue(data?.project?.images?.length - 1)
    } else if (value + change > data?.project?.images?.length - 1) {
      setValue(0)
    } else {
      setValue(value + change)
    }
  }

  const variant = useBreakpointValue({ base: "base", sm: "sm" })

  return (
    <Box>
      <StickyHeader />
      <Center h={"100vh"}>
        <Stack h={"100vh"}>
          <Spacer />
          <Center>
            <HStack maxH={"80vh"} w={"100vw"} px={[1, 5, 5, 5]}>
              <Text fontWeight={"bold"} color="black" fontSize={[25, 64, 64, 64]} cursor={"pointer"} onClick={() => changeValue(-1)}>
                {"<"}
              </Text>
              <Spacer />
              <Image
                src={data?.project?.images[value]}
                cursor="pointer"
                onClick={() => {
                  navigate(-1)
                  setTimeout(() => {
                    window.scrollTo(0, 0)
                  }, 5);
                }}
                maxH={"80vh"}
                maxW={"80vw"}
              />
              <Spacer />
              <Text fontWeight={"bold"} color="black" fontSize={[25, 64, 64, 64]} cursor={"pointer"} onClick={() => changeValue(1)}>
                {">"}
              </Text>
            </HStack>
          </Center>
          {variant === "base" && <Spacer />}
          <Center>
            <RadioGroup onChange={setValue} value={String(value)}>
              <HStack>
                {data?.project?.images.map((image, i) => {
                  return <Radio key={i} value={String(i)} />;
                })}
              </HStack>
            </RadioGroup>
          </Center>
          {variant !== "base" ? <Spacer /> : <Box pb={5} />}
          <Footer />
        </Stack>
      </Center>
    </Box>
  );
}
