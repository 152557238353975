import { Text } from "@chakra-ui/react"

export const _04_K_2002 = () => {
    //04-K-2002-01-moebius-architekt-metaphorische Treppe-Konzept
    const projectID = "04-K-2002"
    const projectName = "moebius-architekt-metaphorische Treppe-Konzept"
    const name = "Metaphorische Treppe - Idee"
    const notification = false
    const text = <Text>
        <br />
        <br />
        <a>Metaphorische Treppe | Lichtspiel | 2002</a>
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 3

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./04-K-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
