import { Text } from "@chakra-ui/react"

export const _08_W_2007 = () => {
    //08-W-2007-01-Möbius-Architekt-Einfamilienhaus-Muenchen-Umbau
    const projectID = "08-W-2007"
    const projectName = "Möbius-Architekt-Einfamilienhaus-Muenchen-Umbau"
    const name = "Umbau Innen Einfamilienhaus - München"
    const notification = false
    const text = <Text>
        Das Haus im Stil der Münchner Gartenstadt war im Innenbereich dunkel und unstrukturiert, so dass im Rahmen der Baumaßnahme eine völlige Neukonzeption erforderlich war. So wurden die Erschließung, die Durchlässigkeit der Geschosse und deren Belichtung sowie die Räume neu angeordnet und umgestaltet.       <br />
        Transparenz und Offenheit mussten auf kleinstem Raum umgesetzt werden.
        Die Oberflächen des Bades wurden mit Tadelakt (bekannt aus orientalischen Bädern) beschichtet und mit Wandheizung versehen, was dem Raum eine angenehme Großzügigkeit verleiht.
        <br />
        <br />
        Einfamilienhaus - München | Umbau | LPH 1-8 | 2007
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 10

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        if (id === "07" || id === "08") {
            continue
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./08-W-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "")}`,
        type: type,
    }
}
