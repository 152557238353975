import { Text } from "@chakra-ui/react"

export const _05_W_2012 = () => {
    //05-W-2012-01-Möbius-Architekt-Einfamilienhaus-Tegernsee-Neubau
    const projectID = "05-W-2012"
    const projectName = "Möbius-Architekt-Einfamilienhaus-Tegernsee-Neubau"
    const name = "Wohnhaus in den Bergen - Kreuth"
    const notification = <Text>Architektouren - wir waren dabei</Text>
    const text = <Text>
        Auf dem Grundstück wurde ergänzend zu der Randbebauung im Norden ein Wohnhaus als Einfamilienhaus in nur 6 Monaten errichtet.
        <br />
        <br />
        Das zweigeschossige Einfamilienhaus ist als rechteckiger Kubus mit Satteldach errichtet worden.
        <br />
        <br />
        Das Haus sollte sich in die Umgebung unter Berücksichtigung der Ortssatzung einfügen und trotzdem modern und eigenständig wirken. So wurden die Fassade aus einer liegenden Lärchenholzverschalung gestaltet, der Eingang mit Sichtbeton und Putz akzentuiert.
        <br />
        <br />
        Wesentlich waren ein offenes Raumkonzept mit Sichtverbindungen zwischen den Räumen und Geschossen, sowie gezielte Blicke auf die umgebenden Berge. Für einen ganzheitlichen Ansatz wurde sowohl die Einrichtung als auch die Beleuchtung mitgestaltet.
        <br />
        <br />
        Das Haus zeichnet sich durch ökologische Baustoffe, diffusionsoffene Konstruktionen und eine Energieversorgung durch Erdsonden aus.
        <br />
        <br />
        Im Anbau sind eine externe Wohnung und die Kellerersatzräume untergebracht. Dadurch konnte auf eine Unterkellerung des Haupthauses verzichtet werden, wodurch die Fläche optimiert und die Folgen einer Überschwemmung der nahe gelegenen Weißach umgangen wurde.
        <br />
        <br />
        Wohnhaus in den Bergen - Kreuth | LPH 1-8 | 2010-2011
        <br />
        <br />
        Ausgezeichnet als Teilnehmer der Architektouren 2017 durch die Bayerische Architektenkammer
        <br />
        <br />
        <Text textDecoration={"underline"} color="blue">
            <a target="_blank" href="https://webwerk-am-meer.de/data/Downloads/05-W-2012-Karte-Möbius-Architekt-Einfamilienhaus-Tegernsee-Neubau.pdf" rel="nofollow">Flyer - download</a>
            <br />
            <br />
            <a target="_blank" href="05-W-2012-Presse Miesbacher Merkur 23.06.2012.pdf" rel="nofollow">Zeitungsbericht -Miesbacger Merkur- Samstag 23.Juni 2012 - download</a>
            <br />
            <br />
            <a target="_blank" href="https://webwerk-am-meer.de/data/Downloads/05-W-2012-Presse Das gelbe Blatt 16.06.2012-1.pdf" rel="nofollow">Zeitungsbericht -Das gelbe Blatt- Samstag 16.Juni 2012 - download</a>
            <br />
            <br />
            <a target="_blank" href="https://webwerk-am-meer.de/data/Downloads/05-W-2012-Presse Tegernseer Stimme 14.06.2012.pdf" rel="nofollow">Bericht online -Tegernseer Stimme- 14.Juni 2012-download</a>
        </Text>
    </Text>
    const city = "Kreuth"
    const country = "Deutschland"
    const imageLength = 12

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        if (id === `03`) {
            continue
        }

        const image = require(`./${projectID}-${id}-${projectName}.webp`)


        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./05-W-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("moebius-architekt-", "").replace("möbius-architekt-", "").replace("moebius-architekt-", "").replace("möbius-architekt-", "")
            }`,
        type: type,
    }
}
