import {
  HStack,
  Center,
  Box,
  Text,
  Image,
  Spacer,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";

export function ProfileShowcase(props) {
  const { ...rest } = props;

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const size = useBreakpointValue({
    base: "95vw",
    sm: "80vw",
    md: "75vw",
    lg: "60vw",
  });

  return (
    <Center>
      <Stack>
        <HStack w={size} pt={10}>
          <Box
            maxW={variant !== "base" && variant !== "sm" ? "60%" : "95vw"}
            {...rest}
          >
            <Text fontWeight="bold" fontSize={20} color="gray.500">
              {" "}
              Dipl.-Ing. Architekt Holger Möbius <br />
              Inhaber Möbius Architekten
            </Text>
            <br />
            <Text>
              <h2> <b>Publikationen</b> </h2>
              <br />
              Architektouren 2023: kleiner Grund - hoch hinaus, Herrsching
              <br />
              <br />
              GUT GEBAUT – Häuser im Landkreis Miesbach, Volk-Verlag 2018: Der Lohn des Bescheidenen
              <br />
              <br />
              Holzbau-Fachzeitschrift Mikado 9/2017: Titelbericht Fassadengestaltung in Holz
              <br />
              <br />
              Architektouren 2017: Vermessungsamt, Wolfratshausen
              <br />
              <br />
              Architektouren 2012: Wohnhaus in den Bergen, Weißach Kreuth
              <br />
              <br />
              Ausstellung Wohnen im Alter: Bayerischer Landtag München, 2005
            </Text>
          </Box>
          {variant !== "base" && variant !== "sm" && (
            <>
              <Spacer />
              {/*               <Image
                src={
                  "https://www.tierpark-berlin.de/fileadmin/_processed_/7/8/csm_Rothschild_Giraffen_Henri_Ella_Amalka_TierparkBerlin_2e4d75373c.jpg"
                }
                w={variant !== "base" ? "40%" : "80vw"}
              /> */}
            </>
          )}
        </HStack>
        {variant === "base" ||
          (variant === "sm" && (
            <>
              <Spacer />
              {/*               <Image
                src={
                  "https://www.tierpark-berlin.de/fileadmin/_processed_/7/8/csm_Rothschild_Giraffen_Henri_Ella_Amalka_TierparkBerlin_2e4d75373c.jpg"
                }
                w={"80vw"}
                maxW={350}
              /> */}
            </>
          ))}
      </Stack>
    </Center>
  );
}
