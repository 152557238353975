import { Text } from "@chakra-ui/react"

export const _04_OE_2009 = () => {
    //04-OE-2009-01-moebius-architekt-oeffentliche Baumassnahme-Wasserturm-Landsberg-Umbau
    const projectID = "04-OE-2009"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Wasserturm-Landsberg-Umbau"
    const name = "Umbau Wasserturm - Lagerlechfeld"
    const notification = false
    const text = <Text>
        Die Verkleidung des bestehenden Turms stellte eine Gefahr wegen herabfallender Fassadenplatten dar. In einer Kostengegenüberstellung wurden die Kosten für die Sanierung und für den Abbruch verglichen.
        <br />
        Die asbesthaltige Verkleidung und das Dach wurden im Zuge der Sanierung abgebrochen und die darunterliegende Fassade saniert, sodass das „Wahrzeichen“ von Lagerlechfeld wieder in neuem Glanz erstrahlt.
        <br />
        <br />
        Wasserturm - Lagerlechfeld | Sanierung | LPH 1-8 | 2008-2009
    </Text>
    const city = "Lagerlechfeld"
    const country = "Deutschland"
    const imageLength = 4

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    let previewImage
    previewImage = require(`./04-OE-vollansicht.webp`)
    if (window.innerWidth < 500) {
        mainImage = require(`./04-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(previewImage)

    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
