import {
  Stack,
  Image,
  Text,
  HStack,
  Center,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";

export const BigProjectPreview = (props) => {
  const {
    text,
    target,
    benefits,
    number = 1,
    imageSize,
    project,
    ...rest
  } = props;

  const navigate = useNavigate();
  project.link = project?.link?.replace("möbius-architekt-", "").replace("moebius-architekt-", "").replace("möbius-architekt-", "")

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  return (
    <Box>
      {variant !== "base" ? (
        <HStack spacing={20} alignItems={"flex-start"} {...rest}>
          {number % 2 == 0 && (
            <Image
              src={project.image}
              w={imageSize}
              cursor="pointer"
              onClick={() => navigate(project.link)}
            />
          )}
          <Center>
            <Text textAlign="left" fontSize={18} pr={5}>
              {text}
              <Box pt={5}>
                <BsArrowRightCircle
                  fontSize={35}
                  cursor="pointer"
                  onClick={() => navigate(project.link)}
                />
              </Box>
            </Text>
          </Center>
          {number % 2 != 0 && (
            <Image
              src={project.image}
              w={imageSize}
              cursor="pointer"
              onClick={() => navigate(project.link)}
            />
          )}
        </HStack>
      ) : (
        <Stack>
          <Image
            src={project.image}
            w={"100%"}
            cursor="pointer"
            onClick={() => navigate(project.link)}
            {...rest}
          />
          <Center>
            <Text textAlign="left" maxW={"90vw"} frontSize={15}>
              {text}
              <Box pt={5}>
                <BsArrowRightCircle
                  fontSize={35}
                  cursor="pointer"
                  onClick={() => navigate(project.link)}
                />
              </Box>
            </Text>
          </Center>
        </Stack>
      )}
    </Box>
  );
};
