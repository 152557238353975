import { Text } from "@chakra-ui/react"
import image from './02-K-2014-01-moebius-architekt-Wohnungsbau-Muenchen-Masterplan.webp'
import image1 from './02-K-handy.webp'
export const _02_K_2014 = () => {
    //01-OE-2017-01-moebius-architekt-oeffentliche-Baumassnahme-Vermessungsamt-Wolfratshausen-Neubau
    const projectID = "02-K-2014"
    const projectName = "moebius-architekt-Wohnungsbau-Muenchen-Masterplan"
    const name = "Konzept für einen Wohnungsbau – München"
    const notification = false
    const text = <Text>
        Konzept einer städtischen Wohnbebauung mit Bestandsumbauten und Neuplanungen
        <br />
        <br />
        Wohnungsbau München | Konzeptstudie und Projektentwicklung | 2014
    </Text>
    const city = "München"
    const country = "Deutschland"
    const type = "konzepte"
    return {
        name: name,
        notification: notification,
        image: window.innerWidth > 500 ? image : image1,
        images: [],
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
