import { Text } from "@chakra-ui/react"

export const _03_OE_2011 = () => {
    //03-OE-2011-01-moebius-architekt-oeffentliche Baumassnahme-Hangar-Bayern-Umbau
    const projectID = "03-OE-2011"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Hangar-Bayern-Umbau"
    const name = "Flugzeugwartungshalle – Bayern"
    const notification = false
    const text = <Text>
        Die bestehende Wartungshalle war in jeder Hinsicht sanierungsbedürftig. Ziel war es im Rahmen der Baumaßnahme die Halle wieder auf den neuesten technischen Stand zu bringen und den heutigen Anforderungen an Arbeitsplätze entsprechend zu gestalten. Hierzu wurde die Halle auch aufgrund der zahlreichen Schadstoffe komplett bis auf die Rahmenkonstruktion zurückgebaut und neu konzipiert.
        <br />
        Der Bau besteht aus einer stützenfreien hohen Halle mit dreiseitig umlaufendem eingeschossigen Außenbund. Diese Grundsystematik wurde beibehalten. Im Außenbund wurden alle dienenden Räume wie Schulungsräume, Werkstätten und Verwaltungsräume neu angeordnet.
        <br />
        Aufgrund seiner Lage direkt neben dem Rollfeld ist die Belästigung durch Fluglärm außerordentlich hoch. Um die Lärmbelästigung so gering wie möglich zu halten, wurde die Konstruktion mehrschichtig aufgebaut. Im Inneren wurde mit dem Sichtmauerwerk und der Beschichtung der Werkstattcharakter aufrechterhalten.
        <br />
        <br />
        Flugzeugwartungshalle - Bayern | Umbau und Neugestaltung | LPH 1-8 | 2009-2011
    </Text>
    const city = "Bayern"
    const country = "Deutschland"
    const imageLength = 8

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./03-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    let originalImage = require("./03-OE-vollansicht.webp")
    imageList.unshift(originalImage)

    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
