import { Stack, Text, Wrap, WrapItem, Center, Box } from "@chakra-ui/react";

export function Employees({ ...rest }) {
  const employees = [
    {
      name: "Katrin Kleinschmidt",
      /* image: "https://randomuser.me/api/portraits/women/88.jpg", */
    },
    {
      name: "Bettina Sitt",
      /* image: "https://randomuser.me/api/portraits/women/95.jpg", */
    },
    {
      name: "Christine Priborsky",
      /* image: "https://randomuser.me/api/portraits/women/70.jpg", */
    },
    {
      name: "Brigitte Borst",
      /* image: "https://randomuser.me/api/portraits/women/0.jpg", */
    },
    {
      name: "Ute Kupzog",
      /* image: "https://randomuser.me/api/portraits/women/39.jpg", */
    },
    {
      name: "Reinhard Pott",
      /* image: "https://randomuser.me/api/portraits/men/21.jpg", */
    },
    {
      name: "Petra Murböck",
      /* image: "https://randomuser.me/api/portraits/women/3.jpg", */
    },
    {
      name: "Anastasios Costanzo",
      /* image: "https://randomuser.me/api/portraits/women/3.jpg", */
    },
    {
      name: "Helma Bock",
      /* image: "https://randomuser.me/api/portraits/women/3.jpg", */
    },
  ];

  return (
    <Center {...rest}>
      <Stack maxW={"90vw"}>
        <Text textAlign={"center"} fontWeight={"bold"} fontSize={27}>
          {" "}
          Mitarbeiter seit 2002{" "}
        </Text>
        <Wrap justify={"center"} spacing={5}>
          {employees.map((e, i) => {
            return (
              <WrapItem key={i}>
                {/*                 <Stack>
                  <Box
                    borderTopRadius={15}
                    backgroundImage={e.image}
                    backgroundRepeat={"no-repeat"}
                    backgroundSize={"cover"}
                    bgColor="black"
                    h={200}
                    w={150}
                  ></Box>
                  <Text fontWeight={"bold"} textAlign={"center"}>
                    {" "}
                    {e.name}{" "}
                  </Text>
                </Stack> */}
                <Text fontWeight={"bold"}>{e.name}</Text>
              </WrapItem>
            );
          })}
        </Wrap>
      </Stack>
    </Center>
  );
}
