import {
  Box,
  Spacer,
  Stack,
  Image,
  Text,
  RadioGroup,
  Radio,
  HStack,
  Center,
  Wrap,
  WrapItem,
  useBreakpointValue
} from "@chakra-ui/react";
import { StickyHeader } from "../components/sticky-header/StickyHeader";
import "../components/css/main.css";
import footer from "../components/images/footer.png";
import { projects } from "../components/static-variables/projects";
import { Footer } from "../components/Footer";
import { mainColor } from "../components/static-variables/mainColor";
import { useEffect, useState, useRef, useMemo } from "react";
import { ProjectContent } from "../components/ProjectContent";
import { ProjectImageCollection } from "../components/ProjectImageCollection";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

function isInViewport() {
  const box = document.querySelector(".box");
  const rect = box?.getBoundingClientRect();
  return (
    rect?.top >= 0 &&
    rect?.left >= 0 &&
    rect?.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function Main() {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams()
  const [value, setValue] = useState(id ? parseInt(id, 10) : 0);
  const [render, setRender] = useState(true);
  let previewProjects = projects.slice(0, 17);
  let project = previewProjects[value];

  const variant = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg" })
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isInViewport()) {
        if (value < previewProjects.length - 1) {
          setValue(parseInt(value, 10) + 1);
        } else {
          setValue(0);
        }
      } else {
        setRender(!render);
      }

    }, 10000);
    return () => clearTimeout(timer);
  }, [setValue, value, render, setRender]);

  function changeValue(change) {
    if (value + change < 0) {
      setValue(previewProjects.length - 1)
      navigate(`/${previewProjects.length - 1}`)
    } else if (value + change > previewProjects.length - 1) {
      setValue(0)
      navigate("/0")
    } else {
      setValue(value + change)
      navigate(`/${parseInt(value, 10) + parseInt(change, 10)}`)
    }
  }

  return (
    <Box>
      <div class={"box"} />
      <StickyHeader resetCarousel={() => setValue(0)} />
      <Image w={"100%"} h={(variant === "lg" || variant === "base") && "100vh"} src={project.image} />
      <Stack w={"100%"} h={(variant === "lg" || variant === "base") && "100vh"} position={(variant === "lg" || variant === "base") && "absolute"} zIndex={5} top={0} left={0} >
        <Center w={"100%"} bgColor="yellow" position="absolute" top={"100px"} zIndex={50}>
          {project.notification && (
            <Box fontSize={17} p={5} color={"white"} textAlign={"center"} fontWeight={"black"} bgColor={mainColor} position={"absolute"} >
              {project.notification}
            </Box>
          )}
        </Center>
        <Spacer />
        {variant !== "base" && (
          <Center>
            <RadioGroup
              onChange={setValue}
              colorScheme="blue.500"
              value={String(value)}
              maxW={"95vw"}
            >
              <Wrap justify="center" spacing={5} >
                {previewProjects.map((p, i) => {
                  return (
                    <WrapItem key={i}>
                      <Radio onClick={() => {
                        setValue(i)
                        navigate(`/${i}`)
                      }} size={variant !== "lg" ? "sm" : "lg"} key={i} value={String(i)} _focus={{ boxShadow: "none" }} />
                    </WrapItem>
                  )
                })}
              </Wrap>
            </RadioGroup>
          </Center>
        )}
        <HStack zIndex={99999} w={"100%"} position={(variant === "lg" || variant === "base") && "absolute"} left={0} top={(window.innerHeight - 100) / 2} px={[2, 5, 5, 5]} >
          <Text fontWeight={"bold"} color="black" fontSize={[38, 30, 30, 64]} cursor={"pointer"} onClick={() => changeValue(-1)}>
            {"<"}
          </Text>
          <Spacer />
          <Text fontWeight={"bold"} color="black" fontSize={[38, 30, 30, 64]} cursor={"pointer"} onClick={() => changeValue(1)}>
            {">"}
          </Text>
        </HStack>
        <Box w={"100%"} >
          <Box w={"100%"} h={["xxpx", "70px", "70px", "70px"]} bgColor="white">
            <Center h={"100%"}>
              <Text
                color={mainColor}
                fontWeight="bold"
                fontSize={[20, 25, 25, 40]}
                textAlign="center"
              >
                {" "}
                {project.name}
              </Text>
            </Center>
          </Box>
        </Box>
      </Stack>
      <Stack pt={6}>
        <ProjectContent project={project} />
        <ProjectImageCollection project={project} />
        <Footer />
      </Stack>
    </Box>
  );
}

export default Main;
