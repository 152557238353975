import {
  HStack,
  Center,
  Box,
  Text,
  Image,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";

export function OfficeShowcase(props) {
  const { ...rest } = props;
  const size = useBreakpointValue({
    base: "95vw",
    sm: "80vw",
    md: "75vw",
    lg: "60vw",
  });
  return (
    <Center>
      <Box w={size} minWidth={350} textAlign={["justify", "justify", "justify", "left"]} {...rest}>
        <Box textAlign={["center", "center", "center", "left"]}>
          <Text fontWeight="bold" fontSize={32} color="gray.500" >
            {" "}
            Von der Kunst des Bauens
          </Text>
          <Text fontSize={20} color="gray.400">
            Bürovorstellung Möbius Architekten
          </Text>
        </Box>
        <br />
        <Text>
          Architektur, Konzeptentwicklung und Mediation sind die drei Schwerpunkte, mit denen sich unser Büro in den letzten 20 Jahren bei über 75 Projekten bewährt hat.
          <br />
          <br />
          <b>Architektur</b>
          <br />
          Von der Bauleitplanung über öffentliche Bauten und Wohngebäude bis zu gewerblichen Bauten wird von uns das gesamte Spektrum architektonischen Gestaltens abgedeckt. Dabei werden von uns alle Architektenleistungen von der Idee über die Ausschreibung bis zur fertigen Umsetzung erbracht. Aufgrund unseres reichen Erfahrungsschatzes und fundierter Sicherheit im Baurecht werden mögliche Streitpunkte gesehen und können so vermieden werden.
          Beim Entwurf wird ein großes Augenmerk auf den Ort, die städtebauliche Identität und den Verbrauch von Ressourcen gelegt. Die Planungen zeichnen sich durch Detailverbundenheit und hohen Gestaltungswillen aus. Die Koordination von Fachingenieuren und Spezialisten anderer Fachbereiche ist uns geläufig und sorgt gemeinsam mit einer durchgängigen und weitsichtigen Kostenkontrolle für ein ruhiges Baugeschehen.
          <br />
          <br />
          <b>Konzeptentwicklung</b>
          <br />
          Von uns werden Machbarkeitsstudien bezogen auf vorgegebene Strukturen entwickelt. Darüber hinaus werden neue Konzepte erarbeitet, die sowohl die Gestaltung als auch die wirtschaftlichen Abläufe berücksichtigen. So werden die Gebäudekosten mit den Bau-, Unterhalts-, Betriebs- und Rückbaukosten einerseits und die Betriebsabläufe und damit verbundenen Personalkosten andererseits für den gewünschten Zeitraum berechnet.
          <br />
          <br />
          <Box textAlign={"left"}>
            <b>Folgende Leistungen werden von uns erbracht:</b> <br />
            <br />
            <b>Architektur:</b>
            <br />
            Neubau, Umbau, Grundinstandsetzung, Sanierung, energetische Sanierung, Generalplanung, Bebauungspläne
            <br />
            <br />
            <b>Konzept:</b>
            <br />
            Nutzungsänderungen, Machbarkeitsstudien, Konzeptentwicklung, Gemeindeentwicklungsplan
            <br />
            <br />
            <b>Mediation:</b>
            <br />
            Mediation im Bauwesen, Mediation als Prozess
          </Box>
        </Text>
      </Box>
    </Center>
  );
}
