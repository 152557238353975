import { Text } from "@chakra-ui/react"

export const _05_K_2004 = () => {
    //05-K-2004-01-moebius-architekt-Pflegeheim-Konzept
    const projectID = "05-K-2004"
    const projectName = "moebius-architekt-Pflegeheim-Konzept"
    const name = "Pflegeheim - Konzept"
    const notification = false
    const text = <Text>
        Unsere Vorstellung war es, dezentrale kleine Häuser mit familiärer Atmosphäre zu schaffen.
        Hierzu wurde ein gestalterisches Konzept entwickelt, das die Möglichkeiten der Selbstbestimmung der älteren Menschen berücksichtigt.
        <br />
        Als wesentlicher Bestandteil der Aufgabe wurde nachgewiesen, dass dieses Konzept wirtschaftlich tragfähig ist. Es stellte sich heraus, dass Häuser mit einer Anzahl von 3 Gruppen mit je 7 bis 8 Bewohnern genauso effizient arbeiten können, wie andere größere Heime.
        <br />
        Das Projekt wurde im Rahmen privatwirtschaftlicher und politischer Veranstaltungen vorgestellt und diskutiert.
        <br />
        <br />
        <a>MPflegeheim  | Konzept | 2005</a>
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 3

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./05-K-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
