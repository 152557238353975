import { Text } from "@chakra-ui/react"

export const _07_W_2007 = () => {
    //07-W-2007-01-Möbius-Architekt-Einfamilienhaus-Woerthsee-Umbau
    const projectID = "07-W-2007"
    const projectName = "Möbius-Architekt-Einfamilienhaus-Woerthsee-Umbau"
    const name = "Erweiterung Einfamilienhaus - Steinebach"
    const notification = false
    const text = <Text>
        Das bestehende Einfamilienhaus wurde für den erwarteten Familienzuwachs zu klein und es sollte mehr Wohnraum geschaffen werden.
        <br />
        Eine in das Haupthaus integrierte und nicht mehr genutzte Garage wurde gemeinsam mit dem Freisitz in das Wohnkonzept aufgenommen.
        <br />
        Ebenso wurde die bestehende Küche als Arbeitszimmer umgebaut und der Wohnraum durch eine neue Küche und einen Essbereich erweitert. Wohnraum und Essplatz öffnen sich zum Garten. Von der Küche hat man Kontakt zur Straße. Durch den Umbau sind so die klassischen Sichtbezüge wieder hergestellt worden.
        <br />
        <br />
        Wohnhaus - Steinebach | Umbau und Erweiterung | LPH 1-8 | 2007
    </Text>
    const city = "Steinebach"
    const country = "Deutschland"
    const imageLength = 8

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./07-W-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "")}`,
        type: type,
    }
}
