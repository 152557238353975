import {
  Image,
  Stack,
  Spacer,
  HStack,
  Box,
  Text,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import '../components/css/scrollable.css'
import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { TbSearch } from 'react-icons/tb'
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";

export function ProjectImageCollection({ project }) {
  const [id, setId] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [showcaseLength, setShowcaseLength] = useState(window.innerWidth / 400)
  const [image, setImage] = useContext(AppContext);
  const navigate = useNavigate();

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  let slider
  let mouseDown = false;
  let startX, scrollLeft;

  let startDragging = function (e) {
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };
  let stopDragging = function (event) {
    mouseDown = false;
  };

  const handleResize = () => {
    let newId = []
    for (let i = 0; i < showcaseLength; i++) {
      newId.push(changeValue(id[i], -1))
    }
    setId(newId)
    setShowcaseLength(window.innerWidth / 400)
  }

  useEffect(() => {

    slider = document.querySelector('.parent') || document.querySelector(".verticalParent");

    slider?.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) { return; }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });

    // Add the event listeners
    slider?.addEventListener('mousedown', startDragging, false);
    slider?.addEventListener('mouseup', stopDragging, false);
    slider?.addEventListener('mouseleave', stopDragging, false);
    window.addEventListener("resize", handleResize)

  }, [])

  let previewImages = []
  for (let i = 0; i < showcaseLength; i++) {
    if (i < project?.images.length) {
      previewImages.push(project?.images[id[i]])
    }
  }

  function changeValue(self, change) {
    if (self + change < 0) {
      return project?.images?.length - 1
    } else if (self + change > project?.images?.length - 1) {
      return 0
    } else {
      return self + change
    }
  }

  return (
    <Stack>
      {project.images.length > 0 && (<>
        <Center>
          {variant === "lg" && (
            <HStack pb={10} /* align={"flex-start"} */ h={350} w={"100%"} px={5}>
              {showcaseLength < project?.images.length && (
                <Text cursor={"pointer"} onClick={() => {
                  let newId = []
                  for (let i = 0; i < showcaseLength; i++) {
                    if (i < project?.images.length) {
                      newId.push(changeValue(id[i], -1))
                    }
                  }
                  setId(newId)
                }
                } fontWeight={"bold"} color="black" fontSize={[38, 64, 64, 64]}>
                  {"<"}
                </Text>
              )}
              <Spacer />
              {previewImages?.map((image, index) => {
                return (
                  <>
                    <Box /* w={300} */ key={index}>
                      <Center w={"100%"}>
                        <Image
                          _hover={{ maxHeight: 300 }}
                          key={index}
                          /* maxW={["90vw", "90vw", "40vw", 270]} */
                          maxH={270}
                          src={image}
                          cursor={"pointer"}
                          onClick={() => {
                            setImage({ project: project, id: project?.images.indexOf(image) });
                            navigate("/galerie");
                          }}
                        />
                      </Center>
                    </Box>
                    <Spacer />
                  </>
                )
              })}
              {/*  <Spacer /> */}
              {showcaseLength < project?.images.length && (
                <Text fontWeight={"bold"} color="black" fontSize={[38, 64, 64, 64]} cursor={"pointer"} onClick={() => {
                  let newId = []
                  for (let i = 0; i < showcaseLength; i++) {
                    if (i < project?.images.length) {
                      newId.push(changeValue(id[i], 1))
                    }
                  }
                  setId(newId)
                }}>
                  {">"}
                </Text>
              )}
            </HStack>
          )}

          {variant === "md" && (
            <div class="parent">
              <HStack w={"90vw"} className="child" align={"flex-start"} h={400}>
                {project?.images?.map((image, index) => {
                  return (
                    <Box key={index} position="relative" zIndex={128641249}>
                      <Image
                        /* _hover={{ maxWidth: "35vw", maxHeight: 410 }} */
                        key={index}
                        maxW={["90vw", "90vw", "40vw", "25vw"]}
                        maxH={250}
                        src={image}
                        cursor={"pointer"}
                      />
                      <Box position="absolute" bgColor="white" borderBottomLeftRadius={20} top={0} right={0} zIndex={2} px={2} py={2}>
                        <TbSearch size={30}
                          onClick={() => {
                            setImage({ project: project, id: project?.images.indexOf(image) });
                            navigate("/galerie");
                          }} />
                      </Box>
                    </Box>
                  );
                })}
              </HStack>
            </div>
          )}


          {(variant === "base" || variant === "sm") && (
            <div class="verticalParent">
              <Stack overflowX={"hidden"} overflowY={"auto"} maxH={"80vh"} className={"verticalChild"}>
                {project?.images?.map((image, index) => {
                  return (
                    <Box position="relative" key={index}>
                      <Image
                        key={index}
                        src={image}
                        cursor={"pointer"}
                      />
                      <Box position="absolute" bgColor="white" borderBottomLeftRadius={20} top={0} right={0} zIndex={2} px={2} py={2}>
                        <TbSearch size={30} onClick={() => {
                          setImage({ project: project, id: project?.images.indexOf(image) });
                          navigate("/galerie");
                        }} />
                      </Box>
                    </Box>
                  )
                })}
              </Stack>
            </div>
          )}

        </Center>
      </>)
      }
    </Stack >
  );
}
