import { HStack, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Footer } from "../components/Footer";
import { StickyHeader } from "../components/sticky-header/StickyHeader";
import { MdWebAsset } from 'react-icons/md'

export function Imprint() {
  //
  const variant = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg" })
  return (
    <>
      <StickyHeader />
      <Stack spacing={2} maxW={["90vw", "75vw", "75vw", "75vw"]} align={"justify"} mx={variant === "base" ? 5 : 15} pt={10}>
        <Text fontWeight="bold" fontSize={30}>
          {" "}
          Impressum{" "}
        </Text>
        <Text>
          {" "}
          Holger Möbius Dipl. Ing. Architekt
          <br />
          Madeleine-Ruoff-Str. 44,
          <br />
          82211 Herrsching
          <br />
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:info@moebius-architekten.de";
              e.preventDefault();
            }}
          >
            info@moebius-architekten.de
          </Link>
          <br />
          Tel +49 (0)8152-794802
          <br />
        </Text>
        <br />
        <Text >
          Eintrag in die Architektenliste der Bayerischen Architektenkammer,
          <br />
          Waisenhausstraße 4, 80637 München, Germany unter Nr.177 989
          <br />
          Hinweise zum bayerischen Architektengesetz und der Berufsordnung sind
          unter <a href="www.byak.de" rel="nofollow">www.byak.de</a> abrufbar.
        </Text>
        <Text>
          <br />
          Umsatzsteuer ID-Nummer: DE 16125210337
          <br />
          <br />
          Die Bildrechte auf diesen Seiten liegen bei Holger Möbius
          <br />
          <br />
          <Text style={{ hyphens: "auto" }} align={"justify"}>
            Wir behalten uns das Recht vor jederzeit Änderung oder Ergänzungen der
            hier bereitgestellten Informationen vorzunehmen.
          </Text>
        </Text>
        {/*       <Text>
        <br />
        Webdesign von raumregime.de - Florian Rüger und moebius-architekten.de -
        Katrin Kleinschmidt
      </Text> */}
        <Text pt={5}>
          <strong>Gestaltung, Konzeption, technische Umsetzung</strong>
          <br />
          Lukas Körber
          <br />

          WebWerk am Meer
          <br />
          Webdesign- und Marketingagentur
          <br />
          <strong>
            <a href={"https://www.webwerk-am-meer.de"}>
              <HStack>
                <MdWebAsset size={28} /> <Text> www.webwerk-am-meer.de </Text>
              </HStack>
            </a>
          </strong>
        </Text>
        <Text pt={5} align={["justify", "left", "left", "left"]}>
          <strong>Haftungsausschluss Haftung für Inhalte</strong>
          <br />
          Die Inhalte meiner Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich
          jedoch keine Gewähr übernehmen. Als Diensteanbieter bin ich gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als
          Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend
          entfernen.
        </Text>
        <Text pt={5} align={["justify", "left", "left", "left"]}>
          <strong>Haftung für Links</strong>
          <br /> Mein Angebot enthält Links zu externen Webseiten Dritter, auf
          deren Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werde ich derartige Links
          umgehend entfernen.
        </Text>
        <Text pt={5} align={["justify", "left", "left", "left"]}>
          <strong>Urheberrecht</strong> <br /> Die durch die Seitenbetreiber
          erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
          deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
          und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
          bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
          Erstellers. Downloads und Kopien dieser Seite sind nur für den
          privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
          auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
          als solche gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitte ich um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde
          ich derartige Inhalte umgehend entfernen.
        </Text>
        <Text pt={5} align={["justify", "left", "left", "left"]}>
          <strong>Datenschutz </strong>
          <br />
          Die Nutzung meiner Webseite ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf meinen Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben. Ich weise darauf hin, dass
          die Datenübertragung im Internet (z.B. bei der Kommunikation per
          E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
          Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von
          im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch
          Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung
          und Informationsmaterialien wird hiermit ausdrücklich widersprochen.
          Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
          Schritte im Falle der unverlangten Zusendung von Werbeinformationen,
          etwa durch Spam-Mails, vor.
        </Text>
        <Text pt={5} align={["justify", "left", "left", "left"]}>
          <strong>Statistische Auswertungen mit PIWIK</strong> <br /> Diese
          Website benutzt Piwik, eine Open-Source-Software zur statistischen
          Auswertung der Besucherzugriffe. Piwik verwendet sog. "Cookies",
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen.Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung dieses
          Internetangebotes werden auf dem Server des Anbieters in Deutschland
          gespeichert. Die IP-Adresse wird sofort nach der Verarbeitung und vor
          deren Speicherung anonymisiert.
        </Text>
      </Stack>
      <br />
      <Footer />
    </>
  );
}
