import {
  HStack,
  Center,
  Box,
  Text,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ImInfo } from "react-icons/im";

const dates = [
  /*   {
    date: "1965",
    dateRange: "1965",
    text: "Geboren am 26.04.1965 in Braunschweig",
    pl: "26vw",
  }, */
  {
    date: "1991",
    dateRange: "1991",
    text: "Lehre Steinmetz / Steinbildhauer",
    pl: "0vw",
  },
  {
    date: "1998",
    dateRange: "1998",
    text: "Diplom, Dipl.-Ing. Architekt / Technische Universität, München",
    pl: "7vw",
  },
  {
    date: "2002",
    dateRange: "1998 - 2002 ",
    text: (
      <Text>
        "Kiessler und Partner Architekten, München Sellack Architekten
        Ingenieure, München"
        <br />
        <br />
        2002 : Gründung Architekturbüro Möbius
      </Text>
    ),
    pl: "4vw",
  },
  {
    date: "2005",
    dateRange: "2005",
    text: "Seit 2005 Wirtschaftsmediator",
    pl: "3vw",
  },
  {
    date: "2022",
    dateRange: "2022",
    text: "20 Jahre Architekturbüro Möbius",
    pl: "17vw",
  },
];

const InfoIcon = ({ date, text }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Center
          tabIndex="0"
          role="button"
          aria-label="Some box"
          children="Click"
        >
          <ImInfo />
        </Center>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader> {date} </PopoverHeader>
        <PopoverBody> {text} </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export function CV(props) {
  const { ...rest } = props;
  const variant = useBreakpointValue({ base: "base", sm: "sm" });
  return (
    <Box py={10} w={"100%"}>
      <Center w={"100%"}>
        <HStack textAlign={"center"} fontWeight={"bold"}>
          {dates.map((date, i) => {
            return (
              <Stack pl={date.pl} key={i}>
                <InfoIcon date={date.dateRange} text={date.text} />
                <Text> {date.date}</Text>
                <Center>
                  <Box w={"5px"} h={"15px"} bgColor={"black"}>
                    {" "}
                  </Box>
                </Center>
              </Stack>
            );
          })}
        </HStack>
      </Center>
      <Box w={"100%"} h={"5px"} bgColor={"black"} />
    </Box>
  );
}
