import {
  Box,
  HStack,
  Spacer,
  Image,
  useBreakpointValue,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { MenuDrawer } from "./MenuDrawer";
import "./sticky-header.css";
import { useNavigate } from "react-router-dom";

export function StickyHeader({ resetCarousel }) {
  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });
  const navigate = useNavigate();


  const ProjectMenu = () => {
    const { isOpen: isOpenThis, onOpen: onOpenThis, onClose: onCloseThis } = useDisclosure();

    /*     const categories = [
          { name: "Öffentliche Projekte", link: "/oeffentliche-projekte" },
          { name: "Wohnen", link: "/wohn-projekte" },
          { name: "Konzepte", link: "/konzepte" },
        ]; */

    const categories = [
      { name: "Öffentliche Projekte", link: "/oeffentliche-projekte" },
      { name: "Wohnen", link: "/wohn-projekte" },
      { name: "Konzepte", link: "/konzepte" },
    ];

    return (
      <Box h={"100%"} onMouseLeave={onCloseThis} zIndex={2} onMouseEnter={onOpenThis}>
        <Spacer />
        <Menu borderRadius={0} isOpen={isOpenThis} matchWidth={true} spacing={0}>
          <MenuButton
            h={"100%"}
            variant={"unstyled"}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="0px"
            _focus={{ boxShadow: "none" }}
          >
            Projekte
          </MenuButton>
          <MenuList minW={"0px"} px={3} /* w={"250px"} */ /* ml={-6} */ mt={-2} borderRadius={0}>
            {categories.map((category, index) => {
              return (
                <Box key={index} bgColor="white" /* w={"195px"} */>
                  {/* <a href={category.link}> */}
                  <MenuItem color="black" /* w={"190px"} */ textAlign="center" bgColor="white" fontSize={[12, 12, 19, 19]} onClick={() => navigate(category.link)}>
                    <Text textAlign="center">
                      {category.name}
                    </Text>
                  </MenuItem>
                  {/* </a> */}
                </Box>
              );
            })}
          </MenuList>
        </Menu>
        <Spacer />
      </Box>
    );
  };

  return (
    <Box
      zIndex={100}
      className="sticky"
      bgColor="white"
      borderBottomWidth={"2px"}
      borderColor={"black"}
    >
      <HStack spacing={10} mx={variant === "base" ? 5 : 15} color={"black"}>
        {variant === "md" || variant === "lg" ? (
          <>
            <Box>
              <HStack
                fontWeight="bold"
                fontSize={24}
                cursor="pointer"
                onClick={() => navigate("/")}
              >
                <Text color="black"> MÖBIUS </Text>
                <Text color="gray.600"> ARCHITEKTEN</Text>
              </HStack>
            </Box>
            <Spacer />
            <HStack spacing={10} mx={15} color="black">
              <Text cursor="pointer" onClick={() => {
                navigate("/")
                resetCarousel && resetCarousel()
              }}>
                {" "}
                Start{" "}
              </Text>
              <ProjectMenu />
              <Text cursor="pointer" onClick={() => navigate("/buero")}>
                Büro
              </Text>
              {/*               <Text cursor="pointer" onClick={() => navigate("/kontakt")}>
                {" "}
                Kontakt{" "}
              </Text> */}
            </HStack>
          </>
        ) : (
          <HStack w={"100%"}>
            <Box>
              <HStack
                fontSize={variant === "base" ? "6vw" : 24}
                letterSpacing={-1}
                cursor="pointer"
                onClick={() => navigate("/")}
              >
                <Text color="black"> MÖBIUS </Text>
                <Text color="gray.600"> ARCHITEKTEN</Text>
              </HStack>
            </Box>
            <Spacer />
            <MenuDrawer />
          </HStack>
        )}
      </HStack>

      {/* <Image src={stickyheader4} /> */}
    </Box>
  );
}
