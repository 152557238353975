import { Text } from "@chakra-ui/react"
import image from './09-K-2005-01-moebius-architekt-Seniorenzentrum-Aichach-Konzept.webp'
import image1 from './09-K-handy.webp'

export const _09_K_2005 = () => {
    //09-K-2005-01-moebius-architekt-Seniorenzentrum-Aichach-Konzept
    const projectID = "09-K-2005"
    const projectName = "moebius-architekt-Seniorenzentrum-Aichach-Konzept"
    const name = "Machbarkeitsstudie - Seniorenzentrum Aichach"
    const notification = false
    const text = <Text>
        Ein Industriegelände im Zentrum von Aichach wurde auf seine Eignung für den Bau eines Seniorenzentrums untersucht.
        <br />
        Geplant waren eine Senioreneinrichtung, unterschiedliche Wohnformen von Wohngemeinschaft bis Einzelhaus, Arztpraxen und ambulanter Dienst.
        <br />
        <br />
        Seniorenzentrum - Aichach | Konzept | 2005
    </Text>
    const city = "Aichach"
    const country = "Deutschland"
    const type = "konzepte"
    return {
        name: name,
        notification: notification,
        image: window.innerWidth > 500 ? image : image1,
        images: [],
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
