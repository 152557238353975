import "./App.css";
import { Main } from "./routes/Main";
import { Stack, Spacer } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Projects } from "./routes/Projects";
import { Profile } from "./routes/Profile";
import { ContactRoute } from "./routes/ContactRoute";
import { Imprint } from "./routes/Imprint";
import { ProjectPage } from "./routes/ProjectPage";
import { getProjects, projects } from "./components/static-variables/projects";
import { PublicProjectsRoute } from "./routes/PublicProjectsRoute";
import { useEffect, useState, createContext } from "react";
import { Galery } from "./routes/Galery";
import ReactDOM from "react-dom/client";
import { AppContext } from "../src/AppContext";
import { Datasecruity } from "./routes/Datasecruity";

function App() {
  const [projectsState, setProjects] = useState(getProjects());
  useEffect(() => {
    /*   let newProjects = []
      for (let i = 0; i < projects.length; i++) {
        let newProject = projects[i]
        newProject.link = projects[i]?.link.toLowerCase().replace("möbius-architekt-", "").replace("moebius-architekt-", "")
        newProjects.push(newProject)
      } */


    for (let i = 0; i < projectsState.length; i++) {
      console.warn("Fertig :", projectsState[i]?.link)
    }
    window.scrollTo(0, 0);


    /*     setProjects(newProjects) */



  }, []);

  const [image, setImage] = useState("baum");

  for (let i = 0; i < projectsState.length; i++) {
    console.warn("Kontrolle :", projectsState[i]?.link)
  }

  return (
    <>
      <AppContext.Provider value={[image, setImage]}>
        <Routes>
          <Route path="*" element={<Main />} />
          <Route path="/" element={<Main />} />
          <Route path="/:id" element={<Main />} />
          <Route path="/konzepte" element={<Projects type={"konzepte"} />} />
          <Route path="/wohn-projekte" element={<Projects type={"wohnen"} />} />
          <Route path="/oeffentliche-projekte" element={<Projects type={"oeffentlich"} />} />
          <Route path="/buero" element={<Profile />} />
          <Route path="/kontakt" element={<ContactRoute />} />
          <Route path="/galerie" element={<Galery />} />
          <Route path="/impressum" element={<Imprint />} />
          <Route path="/datenschutz" element={<Datasecruity />} />
          {/*           <Route
            path="/projekte/oeffentlich"
            element={<PublicProjectsRoute />}
          /> */}
          <Route
            path={projectsState[0]?.link}
            element={<ProjectPage project={projectsState[0]} />}
          />
          <Route
            path={projectsState[1]?.link}
            element={<ProjectPage project={projectsState[1]} />}
          />
          <Route
            path={projectsState[2]?.link}
            element={<ProjectPage project={projectsState[2]} />}
          />
          <Route
            path={projectsState[3]?.link}
            element={<ProjectPage project={projectsState[3]} />}
          />
          <Route
            path={projectsState[4]?.link}
            element={<ProjectPage project={projectsState[4]} />}
          />
          <Route
            path={projectsState[5]?.link}
            element={<ProjectPage project={projectsState[5]} />}
          />
          <Route
            path={projectsState[6]?.link}
            element={<ProjectPage project={projectsState[6]} />}
          />
          <Route
            path={projectsState[7]?.link}
            element={<ProjectPage project={projectsState[7]} />}
          />
          <Route
            path={projectsState[8]?.link}
            element={<ProjectPage project={projectsState[8]} />}
          />
          <Route
            path={projectsState[9]?.link}
            element={<ProjectPage project={projectsState[9]} />}
          />
          <Route
            path={projectsState[10]?.link}
            element={<ProjectPage project={projectsState[10]} />}
          />
          <Route
            path={projectsState[11]?.link}
            element={<ProjectPage project={projectsState[11]} />}
          />
          <Route
            path={projectsState[12]?.link}
            element={<ProjectPage project={projectsState[12]} />}
          />
          <Route
            path={projectsState[13]?.link}
            element={<ProjectPage project={projectsState[13]} />}
          />
          <Route
            path={projectsState[14]?.link}
            element={<ProjectPage project={projectsState[14]} />}
          />
          <Route
            path={projectsState[15]?.link}
            element={<ProjectPage project={projectsState[15]} />}
          />
          <Route
            path={projectsState[16]?.link}
            element={<ProjectPage project={projectsState[16]} />}
          />
          <Route
            path={projectsState[17]?.link}
            element={<ProjectPage project={projectsState[17]} />}
          />
          <Route
            path={projectsState[18]?.link}
            element={<ProjectPage project={projectsState[18]} />}
          />
          <Route
            path={projectsState[19]?.link}
            element={<ProjectPage project={projectsState[19]} />}
          />
          <Route
            path={projectsState[20]?.link}
            element={<ProjectPage project={projectsState[20]} />}
          />
          <Route
            path={projectsState[21]?.link}
            element={<ProjectPage project={projectsState[21]} />}
          />
          <Route
            path={projectsState[22]?.link}
            element={<ProjectPage project={projectsState[22]} />}
          />
          <Route
            path={projectsState[23]?.link}
            element={<ProjectPage project={projectsState[23]} />}
          />
          <Route
            path={projectsState[24]?.link}
            element={<ProjectPage project={projectsState[24]} />}
          />
          <Route
            path={projectsState[25]?.link}
            element={<ProjectPage project={projectsState[25]} />}
          />
          <Route
            path={projectsState[26]?.link}
            element={<ProjectPage project={projectsState[26]} />}
          />
          <Route
            path={projectsState[27]?.link}
            element={<ProjectPage project={projectsState[27]} />}
          />
          <Route
            path={projectsState[28]?.link}
            element={<ProjectPage project={projectsState[27]} />}
          />
        </Routes>
      </AppContext.Provider>
    </>
  );
}

export default App;
