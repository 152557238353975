import { Text } from "@chakra-ui/react"

export const _01_OE_2017 = () => {
    //01-OE-2017-01-moebius-architekt-oeffentliche-Baumassnahme-Vermessungsamt-Wolfratshausen-Neubau
    const projectID = "01-OE-2017"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Vermessungsamt Wolfratshausen-Neubau"
    const name = "Vermessungsamt - Wolfratshausen"
    const notification = <Text>Architektouren - wir waren dabei </Text>
    const text = <Text>
        Das Vermessungsamt in Wolfratshausen ist das erste bayerische mehrgeschossige Verwaltungsgebäude, das im Holzbau und als Passivhaus gebaut wurde. Es zeichnet sich durch klare Formensprache, die Verwendung heimischer Rohstoffe und eine Unterschreitung der Energieeinsparverordnung um 42% aus.
        <br />
        Die weitgehend vorgefertigten Bauteile ermöglichten ein sehr zügiges Bauen. Die Schiebeelemente an den Fenstern lassen sich nach den individuellen Bedürfnissen verschließen und erlauben selbst in geschlossenem Zustand ausreichend Außenkontakt.
        Das Gebäude ist lichtdurchflutet und hell, zudem erlaubt es aufgrund der einfachen baulichen Struktur jederzeit eine Anpassung im Inneren.
        <br />
        Ausgezeichnet als Teilnehmer der Architektouren 2017 durch die Bayerische Architektenkammer
        <br />
        <br />
        <Text textDecoration={"underline"} color="blue">
            <a href="https://webwerk-am-meer.de/data/Downloads/01-OE-2017-Flyer-moebius-architekt-oeffentliche Baumassnahme-Vermessungsamt Wolfratshausen-Neubau.pdf" rel="nofollow" target="_blank">Flyer - Download</a>
            <br />
            <br />
            <a href="https://webwerk-am-meer.de/data/Downloads/01-OE-2017-Presse Muenchener Merkur.pdf" rel="nofollow" target="_blank">Zeitungsbericht -Münchener Merkur- Samstag 23.Juni 2012 - download</a>
            <br />
            <br />
            <a href="https://webwerk-am-meer.de/data/Downloads/01-OE-2017-Presse Sueddeutsche Zeitung.pdf" rel="nofollow" target="_blank">Zeitungsbericht -Süddeutsche Zeitung – Freitag 22.Juni 2012 - download</a>
            <br />
            <br />
            <a href="https://webwerk-am-meer.de/data/Downloads/01-OE-2017-Presse mikado.pdf" rel="nofollow" target="_blank">Fachzeitschrift für Holzbau - Mikado -9/2017 - download</a>
        </Text>
        <br />
        <br />

        Vermessungsamt Wolfratshausen | Neubau | LPH 1-8 | 2017
    </Text>
    const city = "Wolfratshausen"
    const country = "Deutschland"
    const imageLength = 12

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./01-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
        //mainImage = require("./01-OE-vollansicht.webp")
    }
    let originalImage = require("./01-OE-vollansicht.webp")
    imageList.unshift(originalImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
