import { Text } from "@chakra-ui/react"

export const _06_K_2014 = () => {
    //06-K-2014-01-moebius-architekt-Dachaufbau-Bibliothek-Muenchen-Entwurf
    const projectID = "06-K-2014"
    const projectName = "moebius-architekt-Dachaufbau-Bibliothek-Muenchen-Entwurf"
    const name = "Dachaufbau auf Hochhaus – Entwurf - München"
    const notification = false
    const text = <Text>
        Entwurf für einen Dachaufbau auf ein Hochhaus am Mittleren Ring in München. Als Nutzung war eine Bibliothek vorgegeben. Der mäandernde Weg symbolisiert die verschlungenen Wege der Erkenntnis und die Plattformen mit den Bücherabteilungen das Wissen auf den Entwicklungsstufen des Seins.
        <br />
        Das Buch „Die Bibliothek von Babel“ von Luis Borges stand dem Entwurf Pate.
        <br />
        <br />
        Dachaufbau – München | Entwurf | 2002
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 3

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./06-K-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
