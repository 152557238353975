import { Text } from "@chakra-ui/react"

export const _07_OE_2001 = () => {
    //07-OE-2001-01-moebius-architekt-oeffentliche Baumassnahme-Augsburg-Gericht-Umbau
    const projectID = "07-OE-2001"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Augsburg-Gericht-Umbau"
    const name = "Justizgebäude Augsburg"
    const notification = false
    const text = <Text>
        Das Justizgebäude Augsburg ist ein denkmalgeschütztes Bauwerk, das 1875 vom Architekten Reuter fertiggestellt wurde. Im zweiten Weltkrieg wurde das Quartier ausgebombt und in den 50er Jahren wieder aufgebaut. Heute sind in dem Gebäude Land- und Amtsgericht untergebracht.
        <br />
        Durch den Neubau des Strafjustizzentrums konnte ein großer Rechtsbereich ausgelagert werden und andere Gerichtsbarkeiten wieder in das Justizgebäude zurückgeführt werden.
        Bei der Bauaufgabe handelt es sich um zwei getrennte Abschnitte. Zum einen die Sanierung aller ca.300 Büroräume, zum andern der Umbau des Sitzungssaaltraktes.
        <br />
        Bei der Sanierung der Büroräume war entscheidend, dass der Gerichtsbetrieb während der Baumaßnahme ungestört weitergehen musste. In sechs in sich abgeschlossenen Zyklen von je einem Monat wurden alle Räume grundsaniert. Im zweiten Abschnitt wurde der Sitzungssaaltrakt so neu gestaltet, dass er den Anforderungen einer nun ausschließlich zivilen Gerichtsbarkeit gerecht wird.
        <br />
        <br />
        Justizgebäude - Augsburg | Umbau und Sanierung | 2001-2003
    </Text>
    const city = "Augsburg"
    const country = "Deutschland"
    const imageLength = 4

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./07-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
