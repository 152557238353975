import { Stack, Spacer } from "@chakra-ui/react";
import { Footer } from "../components/Footer";
import { StickyHeader } from "../components/sticky-header/StickyHeader";
import { ContactForm } from "../components/ContactForm";

export function ContactRoute() {
  return (
    <>
      <StickyHeader />
      <Stack h={"100vh"}>
        <Spacer />
        <ContactForm pb={20} />
        <Spacer />
        <Footer position={"absolute"} bottom={0} left={0} />
      </Stack>
    </>

  );
}
