import {
  Stack,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Input,
  IconButton,
  Button,
  Box,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";
import { mainColor } from "../static-variables/mainColor";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { GrProjects, GrContact } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";

export function MenuDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [showProjects, setShowProjects] = useState(false)
  const navigate = useNavigate();
  return (
    <>
      <GiHamburgerMenu ref={btnRef} onClick={onOpen} />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Box>
              <HStack
                fontSize={24}
                letterSpacing={-1}
                cursor="pointer"
                onClick={() => navigate("/kontakt")}
              >
                <Text color="black"> MÖBIUS </Text>
                <Text color="gray.600"> ARCHITEKTEN</Text>
              </HStack>
            </Box>
          </DrawerHeader>

          <DrawerBody>
            <Stack fontSize={24} fontWeight="bold" h={"100%"}>
              <Text cursor="pointer" onClick={() => navigate("/")}>
                Start
              </Text>
              <HStack spacing={1}>
                <Text cursor="pointer" onClick={() => setShowProjects(!showProjects)}>
                  Projekte
                </Text>
                {showProjects == true ? <BsChevronUp fontSize={20} /> : <BsChevronDown fontSize={20} />}
              </HStack>

              {showProjects && (
                <Stack fontWeight={"semibold"} fontSize={20} pl={5}>
                  <Text cursor="pointer" onClick={() => navigate("/wohn-projekte")}>
                    Wohnen
                  </Text>
                  <Text cursor="pointer" onClick={() => navigate("/wohn-projekte")}>
                    Öffentlich
                  </Text>
                  <Text cursor="pointer" onClick={() => navigate("/wohn-projekte")}>
                    Konzepte
                  </Text>
                </Stack>
              )}
              <Text cursor="pointer" onClick={() => navigate("/buero")}>
                Büro
              </Text>
              <Text cursor="pointer" onClick={() => navigate("/kontakt")}>
                {" "}
                Kontakt{" "}
              </Text>
              <Spacer />
              <Stack fontWeight="normal" fontSize={20}>
                <Text cursor={"pointer"} onClick={(e) => {
                  window.location.href =
                    "tel:08152794802"
                  e.preventDefault();
                }}> Tel +49 (0)8152-794802 </Text>

                <Text>
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href =
                        "mailto:info@moebius-architekten.de";
                      e.preventDefault();
                    }}
                  >
                    info@moebius-architekten.de
                  </Link>
                </Text>
                <Text> Madeleine-Ruoff-Str. 44, 82211 Herrsching </Text>
              </Stack>
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            {/*             <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
