import { Text } from "@chakra-ui/react"

import image0 from './02-W-2019-01-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau-mobile.webp'
import image1 from './02-W-2019-01-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image2 from './02-W-2019-02-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image3 from './02-W-2019-03-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image4 from './02-W-2019-04-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image5 from './02-W-2019-05-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image6 from './02-W-2019-06-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image7 from './02-W-2019-07-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image8 from './02-W-2019-08-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image9 from './02-W-2019-09-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image10 from './02-W-2019-10-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'
import image11 from './02-W-2019-11-Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau.webp'

export const _02_W_2019 = () => {
    const projectID = "02-W-2019"
    const projectName = "Möbius-Architekt-Einfamilienhaus-Muenchen-Neubau"
    const name = "Neubau eines Einfamilienhauses in München"
    const notification = false
    const text = <Text>
        Am Isarhang wurde ein Einfamilienhaus für eine Familie mit Kindern geplant, das Arbeiten und Wohnen mit schönen Ausblicken ins Grüne ermöglicht.
        <br />
        Die Fassade der zwei Baukörper wurden mit Schiefer und hellem Putz kontrastreich gestaltet, um das Haus kleiner und leichter wirken zu lassen. Das Wohnen wurde wegen der besseren Aussicht in das 1. Obergeschoss mit großer Terrasse gelegt.
        <br />
        Die Vernetzung der Stockwerke in offener Bauweise waren wesentliche Entwurfskriterien.
        Ökologische und energetische Gesichtspunkte wurden ebenso verfolgt wie gutes Raumklima und nachhaltiges Bauen.
        <br />
        <br />
        Einfamilienhaus München | Neubau | LPH 1-9| 2018-2019
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 11

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11]

    return {
        name: name,
        notification: notification,
        image: window.innerWidth > 500 ? image1 : image0,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/wohnen/einfamilienhaus-muenchen-neubau`,
        type: type,
    }
}
