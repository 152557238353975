import {
  Box,
  Stack,
  Center,
  Text,
  Spacer,
  Image,
  HStack,
  RadioGroup,
  Radio,
  Wrap,
  WrapItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { StickyHeader } from "../components/sticky-header/StickyHeader";
import { Footer } from "../components/Footer";
import { useEffect, useState } from "react";
import "../components/css/main.css";
import { mainColor } from "../components/static-variables/mainColor";
import { ShowAllProjects } from "../components/ShowAllProjects";
import { useParams } from "react-router-dom";

function isInViewport() {
  const box = document.querySelector(".box");
  const rect = box?.getBoundingClientRect();
  return (
    rect?.top >= 0 &&
    rect?.left >= 0 &&
    rect?.bottom <=
    (window.innerHeight || document.documentElement.clientHeight) &&
    rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function Projects({ type }) {
  let { id } = useParams()

  let oeffentlich
  let wohnen
  let konzept
  if (window.innerWidth < 500) {
    oeffentlich = require(`../components/data/Oeffentlich/01-OE-2017-WOLF-Vermessungsamt/01-OE-handy.webp`)
    wohnen = require("../components/data/Wohnen/01-W-2022 Reihenendhaus - Herrsching/01-W-handy.webp")
    konzept = require("../components/data/Konzepte/01-K-2014-Rathaus-Seefeld/01-K-handy.webp")
  } else {
    oeffentlich = require(`../components/data/Oeffentlich/01-OE-2017-WOLF-Vermessungsamt/01-OE-2017-01-moebius-architekt-oeffentliche Baumassnahme-Vermessungsamt Wolfratshausen-Neubau.webp`)
    wohnen = require("../components/data/Wohnen/01-W-2022 Reihenendhaus - Herrsching/01-W-2022-01-Möbius-Architekt-Reihenhaus-Herrsching-Neubau.webp")
    konzept = require("../components/data/Konzepte/01-K-2014-Rathaus-Seefeld/01-K-2014-01-moebius-architekt-Rathaus-Seefeld-Wettbewerb.webp")
  }

  const categories = [
    { name: "Öffentliche Projekte", image: oeffentlich },
    { name: "Wohnen", image: wohnen },
    { name: "Konzepte", image: konzept },
  ];

  const [value, setValue] = useState(id ? parseInt(id, 10) : 0);
  const [render, setRender] = useState(true);
  const variant = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg" })

  /*   let category = categories[value]; */
  const [category, setCategory] = useState();

  /*   console.warn("Category : ", category)
    console.warn("Type :", type) */

  useEffect(() => {
    if (type === "oeffentlich") {
      setCategory(categories[0])
    } else if (type === "wohnen") {
      setCategory(categories[1])
    } else {
      setCategory(categories[2])
    }
  }, [type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /*   useEffect(() => {
      const timer = setTimeout(() => {
        if (isInViewport()) {
          if (value < categories.length - 1) {
            setValue(parseInt(value, 10) + 1);
          } else {
            setValue(0);
          }
        } else {
          setRender(!render);
        }
  
      }, 10000);
      return () => clearTimeout(timer);
    }, [setValue, value, render, setRender]); */

  return (
    <>
      <div class={"box"} />
      <StickyHeader />
      <Image w={"100%"} h={(variant === "lg" || variant === "base") && "100vh"} src={category?.image} />
      <Stack w={"100%"} h={(variant === "lg" || variant === "base") && "100vh"} position={(variant === "lg" || variant === "base") && "absolute"} zIndex={5} top={0} left={0}>
        <Spacer />
        {/* <Center>
          <RadioGroup
            onChange={setValue}
            colorScheme="blue.500"
            value={String(value)}
          >
            <Wrap justify="center" spacing={5} >
              {categories.map((p, i) => {
                return (
                  <WrapItem>
                    <Radio key={i} value={String(i)} />
                  </WrapItem>
                )
              })}
            </Wrap>
          </RadioGroup>
        </Center> */}
        <Box w={"100%"}>
          <Box w={"100%"} h={"70px"} bgColor="white">
            <Center h={"100%"}>
              <Text
                color={mainColor}
                fontWeight="bold"
                fontSize={[20, 25, 25, 40]}
                textAlign="center"
              >
                {" "}
                {category?.name}
              </Text>
            </Center>
          </Box>
        </Box>
      </Stack>
      <Box py={10}>
        <ShowAllProjects
          type={
            category?.name === "Öffentliche Projekte"
              ? "oeffentlich"
              : category?.name === "Wohnen"
                ? "wohnen"
                : category?.name === "Konzepte" && "konzepte"
          }
        />
      </Box>
      <Footer />
    </>
  );
}
