import { Text } from "@chakra-ui/react"

export const _02_OE_2013 = () => {
    //02-OE-2013-01-moebius-architekt-oeffentliche Baumassnahme-Veranstaltungszentrum-Garmisch-Partenkirchen-Umbau
    const projectID = "02-OE-2013"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Veranstaltungszentrum-Garmisch-Partenkirchen-Umbau"
    const name = "Veranstaltungszentrum - Garmisch"
    const notification = false
    const text = <Text>
        Der bestehende Veranstaltungssaal sollte umgebaut und durch einen Küchentrakt ergänzt werden.
        <br />
        Der oberbayerische Stil des über 50m langen Bestandsgebäudes wurde im Äußeren nicht verändert, jedoch als Kontrapunkt durch einen flachen modernen Anbau ergänzt. In dem Anbau ist die moderne Küche untergebracht, von der aus sowohl der Biergarten, als auch der Saal bewirtet werden kann.
        <br />
        <br />
        Veranstaltungsgebäude Garmisch | Sanierung und Anbau | LPH 1-8 | 2013-2014
    </Text>
    const city = "Garmisch"
    const country = "Deutschland"
    const imageLength = 5

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./02-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
