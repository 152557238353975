import { Text, useBreakpointValue } from "@chakra-ui/react"
import { _03_W_2014 } from "../03-W-2014-Mehrfamilienhaus-Muenchen-Steinheil/03-W-2014-Mehrfamilienhaus-Muenchen-Steinheil"
import '../../../css/main.css'


export const _01_W_2022 = () => {
    const projectID = "01-W-2022"
    const projectName = "Möbius-Architekt-Reihenhaus-Herrsching-Neubau"
    const name = "Neubau eines Reihenhauses in Herrsching"
    const notification = <a target="_blank" href="https://webwerk-am-meer.de/data/Downloads/01-W-2022-14-Karte Architekturen-Möbius-Architekt-Reihenhaus-Herrsching-Neubau.pdf" rel="nofollow"><Text>{`ARCHITEKTOUREN 2023 - 24.6. & 25.6. -  jetzt Flyer downloaden`} </Text></a>
    const text = <Text>
        Ein sehr kleines Grundstück, die Lage im Überschwemmungsgebiet und das umfangreiche Raumprogramm waren die Herausforderung der Planungsaufgabe. Die Lage des Hauses in zweiter Reihe zum Ammersee verlangte nach einer Lösung, die Sicht auf den See und zusätzlich in den Obergeschossen auf die Berge und die Klosterkirche Andechs ermöglicht. Das Haus sollte offen sein und zugleich ein Rückzugsort, sowie hell und lichtdurchflutet.
        So wurde das Wohnen in die beiden obersten Etagen gelegt. Das Hauptwohngeschoss wird vom Eingang offen mit einer durchgehenden einläufigen Treppe erreicht.  Das Dachgeschoss mit seinen beiden Terrassen (Ost und West), dem Grünstreifen und dem offenen Kamin sind Oase und Rückzugsort zugleich. Alle Geschosse sind mit einem Aufzug verbunden.
        Bezüglich Nachhaltigkeit und Energieeffizienz entspricht das Gebäude den höchsten Standards, so sind die Grünflächen alle intensiv biodivers begrünt und mit Regenwasserrückhaltung versehen, es wurden keine Verbundstoffe eingesetzt, auf die Ökologie der einzelnen Produkte wurde geachtet. Die Wände wurden diffusionsoffen gestaltet, Grundwasserwärmepumpe und Solaranlage machen das Haus weitgehend frei von öffentlicher energetischer Versorgung.
        Die Hochwasserschotts sind unsichtbar in die hinterlüfteten Schieferfassade integriert.
        <br />
        <br />
        Ausgezeichnet als Teilnehmer der Architektouren 2023 durch die Bayerische Architektenkammer
        <br />
        <br />
        <Text textDecoration={"underline"} color="blue">
            <a target="_blank" href="https://webwerk-am-meer.de/data/Downloads/01-W-2022-14-Karte Architekturen-Möbius-Architekt-Reihenhaus-Herrsching-Neubau.pdf" rel="nofollow">Flyer - Download</a>
        </Text>
    </Text>
    const city = "Herrsching"
    const country = "Deutschland"
    const imageLength = 13

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        if (id != "02") {
            imageList.push(image)
        }

    }

    let mainImage
    let smartphoneImage = require(`./01-W-handy.webp`)
    if (window.innerWidth < 500) {
        mainImage = smartphoneImage
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
        imageList.unshift(smartphoneImage)
    }
    imageList.unshift(mainImage)

    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "")}`,
        type: type,
    }
}
