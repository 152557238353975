import { Text } from "@chakra-ui/react"

export const _09_OE_2010 = () => {
    //09-OE-2010-01-moebius-architekt-oeffentliche Baumassnahme-Verwaltungsgebaeude-Augsburg-Umbau
    const projectID = "09-OE-2010"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Verwaltungsgebaeude-Augsburg-Umbau"
    const name = "Umbau Verwaltungsgebäude - Kleinaitingen"
    const notification = false
    const text = <Text>
        Im Rahmen des Umzugs der Fachschule für Informationstechnik nach Kleinaitingen musste ein bestehendes Gebäude den Anforderungen der Fachschule entsprechend zum Verwaltungsgebäude umgebaut werden. Hierzu wurde das Gebäude neu organisiert und die Hülle von asbesthaltiger Verkleidung befreit und entsprechend der EnEV ertüchtigt. Auf Wunsch des Nutzers musste das Flachdach durch ein Satteldach ersetzt werden.    <br />
        <br />
        Verwaltungsgebäude - Kleinaitingen | Umbau | LPH 1-8 | 2009-2010
    </Text>
    const city = "Kleinaitingen"
    const country = "Deutschland"
    const imageLength = 4

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./09-OE-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
