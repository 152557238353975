import { Text } from "@chakra-ui/react"

export const _06_W_2008 = () => {
    //06-W-2008-01-moebius-architekt-Einfamilienhaus-Muenchen-Umbau
    const projectID = "06-W-2008"
    const projectName = "moebius-architekt-Einfamilienhaus-Muenchen-Umbau"
    const name = "Dachgeschossausbau – München"
    const notification = false
    const text = <Text>
        Im Rahmen der Generalsanierung eines Wohnhauses im Zentrum von München wurde die zweigeschossige Dachwohnung neu konzipiert.
        <br />
        Die Wohnung zeichnet sich durch die Offenheit aus, die durch die Grundrissgestaltung und die eingesetzten Materialien erreicht wird.
        <br />
        Die Küchenzeile kann hinter einem herabfahrenden Rollo verschwinden.
        Die Beleuchtung taucht die Wohnung wahlweise in weiß und rot.
        <br />
        <br />
        Dachgeschossausbau-München | Sanierung | LPH 6-8 | 2008
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 6

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./06-W-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${("moebius-architekt-Dachgeschossausbau-Muenchen").toLowerCase().replace("möbius-architekt-", "")}`,
        type: type,
    }
}
