import { Text } from "@chakra-ui/react"

export const _03_K_2010 = () => {
    //03-K-2010-01-moebius-architekt-come together-Muenchen-Studie
    const projectID = "03-K-2010"
    const projectName = "moebius-architekt-come together-Muenchen-Studie"
    const name = "Office Konzept - come together - München"
    const notification = false
    const text = <Text>
        Innerhalb eines Büros sollten verschiedene Orte der Entspannung geschaffen werden, um den Austausch und das Wohlbefinden der Mitarbeiter zu fördern und Kreativität zu fördern.
        <br />
        <br />
        <a>Office Konzept - come together - München | Konzept | 2010</a>
    </Text>
    const city = "München"
    const country = "Deutschland"
    const imageLength = 3

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./03-K-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
