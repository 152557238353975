import { Text } from "@chakra-ui/react"

export const _09_W_2006 = () => {
    //09-W-2006-02-moebius-architekt-Einfamilienhaus-Feng shui
    const projectID = "09-W-2006"
    const projectName = "moebius-architekt-Einfamilienhaus-Feng-shui"
    const name = "Wohnhaus - Madagaskar"
    const notification = false
    const text = <Text>
        Für einen Industriellen in Madagaskar wurde ein Wohnhaus mit umfangreichem Wellnessbereich, Bediensteteneinheiten, Gästetrakt und Homeoffice mit Konferenzraum nach den Prinzipien des Fengshui entwickelt.     <br />
        <br />
        Wohnhaus - Madagaskar | Konzept | LPH 1-4 | 2007
    </Text>
    const city = "Madagaskar"
    const country = "Madagaskar"
    const imageLength = 5

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./09-W-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    let originalImage = require("./09-W-vollansicht.webp")
    imageList.unshift(originalImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "")}`,
        type: type,
    }
}
