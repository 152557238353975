import { Stack } from "@chakra-ui/react";
import "../components/css/main.css";

import { StickyHeader } from "../components/sticky-header/StickyHeader";
import { ProjectPageHeader } from "../components/ProjectPageHeader";
import { ProjectContent } from "../components/ProjectContent";
import { Footer } from "../components/Footer";
import { ProjectImageCollection } from "../components/ProjectImageCollection";
import { useEffect } from "react";

export function ProjectPage(props) {
  const { project } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StickyHeader />
      <Stack>
        <ProjectPageHeader project={project} />
        <ProjectContent project={project} />
        <ProjectImageCollection project={project} />
      </Stack>
      <Footer />
    </>
  );
}
