import { Text } from "@chakra-ui/react"

export const _08_K_2004 = () => {
    //08-K-2004-01-moebius-architekt-Pflegeheim-Oesterreich-Wettbewerb
    const projectID = "08-K-2004"
    const projectName = "moebius-architekt-Pflegeheim-Oesterreich-Wettbewerb"
    const name = "Umbau eines Pflegeheims - Horn - Wettbewerb 2.Platz "
    const notification = false
    const text = <Text>
        Der Umbau eines bestehenden Pflegeheims und die Erweiterung um eine Hospizstation waren Aufgabe dieses Wettbewerbs.
        <br />
        Der Entwurf will die bestehende Struktur öffnen, indem innerhalb der Geschosse Kleingruppen gebildet werden. Mit einem Gemeinschaftsbereich im Zentrum und ausreichend Außenkontakt wird für einen sozialen Austausch und Wohncharakter gesorgt. Die Fassade wird um eine Ebene erweitert, damit der Kontakt zur Außenwelt über die gesamte Front erfolgen kann.
        <br />
        Klimatisch und energetisch ist eine Lüftungsanlage vorgesehen, um ausreichend Wärme bei verbesserter Luftqualität und geringen Energiekosten vorzuhalten. Die Fassadenebene mit dem großen Glasanteil sorgt zusätzlich für Energieeffizienz.
        <br />
        <br />
        Stefansheim in Horn | Wettbewerb 2. Platz | 2004
    </Text>
    const city = "Horn"
    const country = "Deutschland"
    const imageLength = 3

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./08-K-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }
    imageList.unshift(mainImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche baumassnahme-", "").replace(" ", "-")}`,
        type: type,
    }
}
