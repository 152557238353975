import {
  Spacer,
  Text,
  Center,
  HStack,
  Stack,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ImMobile } from "react-icons/im";
import { AiOutlineMail, AiOutlineArrowRight } from "react-icons/ai";
import { BsHouse } from "react-icons/bs";
import { useToastHook } from "./useToastHook";
import { mainColor } from "./static-variables/mainColor";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

const ContactData = ({ height, width }) => {
  return (
    <Stack h={height} w={width}>
      <HStack>
        <Box w={75} h={75} bgColor={mainColor}>
          <Center w={"100%"} h={"100%"}>
            <ImMobile color="white" fontSize={40} />
          </Center>
        </Box>
        <Text fontWeight="bold"> 08152 794802 </Text>
      </HStack>
      <Spacer />
      <HStack>
        <Box w={75} h={75} bgColor={mainColor}>
          <Center w={"100%"} h={"100%"}>
            <AiOutlineMail color="white" fontSize={40} />
          </Center>
        </Box>
        <Text fontWeight="bold">
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = "mailto:info@moebius-architekten.de";
              e.preventDefault();
            }}
          >
            info@moebius-architekten.de
          </Link>
        </Text>
      </HStack>
      <Spacer />
      <HStack>
        <Box w={75} h={75} bgColor={mainColor}>
          <Center w={"100%"} h={"100%"}>
            <BsHouse color="white" fontSize={40} />
          </Center>
        </Box>
        <Text fontWeight="bold">
          {" "}
          Madeleine-Ruoff-Str. 44, <br />
          82211 Herrsching
        </Text>
      </HStack>
      {/*       <Button
        fontWeight="bold"
        bgColor={mainColor}
        color="white"
        fontSize={15}
        px={3}
      >
        unverbindlichen Beratungstermin buchen
      </Button> */}
    </Stack>
  );
};

export function ContactForm(props) {
  const { ...rest } = props;
  const [state, newToast] = useToastHook();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const variant = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
  });

  const serviceID = "service_ypvheiq";
  const templateID = "template_wsjyz4t";
  const publicKey = "py8mUoKiu-88u_Zjw";

  const onSubmit = async (data) => {
    const { name, email, subject, message, phone } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        phone,
        message,
      };
      await emailjs.send(serviceID, templateID, templateParams, publicKey);
      reset();
      newToast({
        title: "Danke für ihre Anfrage",
        message:
          "Deine Anfrage wurde versendet, wir melden uns so schnell wie möglich bei Ihnen",
        status: "success",
      });
    } catch (e) {
      console.log(e);
    }
  };
  const contentHeight = 250;
  return (
    <Center >
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack w={"90vw"} {...rest}>
          {variant === "lg" ? (
            <HStack alignItems={"flex-start"}>
              <ContactData height={contentHeight} width={"30vw"} />
            </HStack>
          ) : (
            <Box>
              <Stack alignItems={"flex-start"}>
                <ContactData height={contentHeight} width={"90vw"} />
              </Stack>
            </Box>
          )}
        </Stack>
      </form>
    </Center>
  );
}

export default ContactForm;
