import {
  Box,
  HStack,
  Text,
  Stack,
  Image,
  Spacer,
  Center,
} from "@chakra-ui/react";
import { mainColor } from "./static-variables/mainColor";
import footer from "./images/footer.png";

export function ProjectPageHeader({ project }) {
  return (
    <Box position="relative" h={"100vh"}>
      <Image w={"100%"}
        h={"100%"} src={project.image} />
      <Stack w={"100%"} h={"100%"} position={"absolute"} zIndex={5} top={0} left={0}>
        {project.notification && (
          <Center>
            <Box p={5} bgColor={mainColor} color={"white"} textAlign={"center"} fontWeight={"bold"}>
              {project.notification}
            </Box>
          </Center>
        )}
        <Spacer />
        <Box w={"100%"} >
          <Box w={"100%"} h={["xxpx", "70px", "70px", "70px"]} bgColor="white">
            <Center h={"100%"}>
              <Text
                color={mainColor}
                fontWeight="bold"
                fontSize={[20, 25, 25, 40]}
                textAlign="center"
              >
                {" "}
                {project.name}
              </Text>
            </Center>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
