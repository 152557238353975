import { Text } from "@chakra-ui/react"

export const _10_W_2007 = () => {
    //10-W-2007-01-moebius-architekt-oeffentliche Baumassnahme-Residenz-Praesidenten-Neubau
    const projectID = "10-W-2007"
    const projectName = "moebius-architekt-oeffentliche Baumassnahme-Residenz-Praesidenten-Neubau"
    const name = "Wohnsitz des Präsidenten - Madagaskar"
    const notification = false
    const text = <Text>
        In der Hauptstadt Radavidra sollte das auf einem der Stadthügel bestehende klassizistische Gebäude durch Neubauten zum Wohnsitz für den Präsidenten ergänzt werden.
        <br />
        Der Neubau besteht aus einer erdgeschossigen Plattform mit Festsaal und öffentlichen Räumen sowie einem Empfangsbereich. Darauf stehen zwei gegeneinander verdrehte Kuben die den privaten Bereich und den Gästebereich beherbergen. Der Neubaukomplex wurde mit einem Verbindungsglied an den Altbau angeschlossen.
        <br />
        Die Aufgabe bestand aus Vor- und Entwurfsplanung und einer Innenraumkonzeption mit Materialauswahl sowie der Erstellung eines Corporate Identity.
        <br />
        Die Ergebnisse dienten als Grundlage für die Ausführungsplanung vor Ort.
        <br />
        <br />
        Wohnsitz des Präsidenten - Madagaskar | LPH 1-4 | 2007
    </Text>
    const city = "Madagaskar"
    const country = "Madagaskar"
    const imageLength = 3

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = []
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    }
    let mainImage
    if (window.innerWidth < 500) {
        mainImage = require(`./10-W-handy.webp`)
    } else {
        mainImage = require(`./${projectID}-01-${projectName}.webp`)
    }

    let originalImage = require("./10-W-vollansicht.webp")
    imageList.unshift(originalImage)
    return {
        name: name,
        notification: notification,
        image: mainImage,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/${type}/${projectName.toLowerCase().replace("möbius-architekt-", "").replace("oeffentliche ", "")}`,
        type: type,
    }
}
