import footer from "../components/images/footer.png";
import { Box, Stack, Spacer, Image, Center, useBreakpointValue } from "@chakra-ui/react";
import { StickyHeader } from "../components/sticky-header/StickyHeader";
import { ProfileShowcase } from "../components/ProfileShowcase";
import { OfficeShowcase } from "../components/OfficeShowcase";
import { Footer } from "../components/Footer";
import { CV } from "../components/CV";
import { ContactForm } from "../components/ContactForm";
import "../components/css/profile.css";
import { useEffect } from "react";
import { Employees } from "../components/Employees";
import mainImage from '../components/data/Büro/Buero-02.webp'
import mainSmartphoneImage from '../components/data/Büro/Buero-01.webp'

export function Profile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const variant = useBreakpointValue({ base: "base", sm: "sm", md: "md", lg: "lg" })

  /*   object-fit: cover;
    width: 50px;
    height: 100px; */

  return (
    <>
      <StickyHeader />
      {/* <Box boxSizing="border-box" h={(variant === "base" || variant === "lg") ? "100vh" : "auto"} > */}
      <Image objectFit={"cover"} w={"100%"} h={(variant === "base" || variant === "lg") ? "100vh" : "auto"} src={variant === "base" ? mainSmartphoneImage : mainImage} /* backgroundSize={`${window.innerWidth * 1.1}px ${window.innerHeight * 1.1}px`} display={"inline-block"} */ />
      {/* </Box> */}
      <Center>
        <Stack spacing={10} w={"100%"}>
          <OfficeShowcase />
          <ContactForm w={["95vw", "80vw", "75vw", "60vw"]} />
          <ProfileShowcase />
          <CV pt={25} />
          <Employees pt={5} />
          <Footer />
        </Stack>
      </Center>
    </>
  );
}
