import { Text } from "@chakra-ui/react"
import image0 from './03-W-handy.webp'
import image1 from './03-W-2014-01-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image2 from './03-W-2014-02-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image3 from './03-W-2014-03-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image4 from './03-W-2014-04-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image5 from './03-W-2014-05-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image6 from './03-W-2014-06-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image7 from './03-W-2014-07-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image8 from './03-W-2014-08-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'
import image9 from './03-W-2014-09-Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau.webp'


export const _03_W_2014 = () => {
    //03-W-2014-01-Möbius-Architekt-Mehrfamilienhaus-München-Neubau
    const projectID = "03-W-2014"
    const projectName = "Möbius-Architekt-Mehrfamilienhaus-Muenchen-Neubau"
    const name = "Neubau eines Mehrfamilienhauses in München"
    const notification = false
    const text = <Text>
        In einer Baulücke in München-Schwabing wurde unter dem Gesichtspunkt der optimalen Ausnutzung des Grundstücks ein straßenbegleitendes Vorderhaus und eine Hofbebauung geplant.  <br />
        Die Stellplätze sind platzsparend in einem Hochregallager bis 4 Stockwerke unter der Oberfläche realisiert.
        <br />
        Die Fassade wurde in der Tiefe differenziert gestaltet, um eine Auflockerung zu erzielen.
        Auf offene lichtdurchflutete und anmutig wirkende Treppenhäuser und ausreichend Licht in den Wohnungen wurde trotz der engen Bebauung großer Wert gelegt. Alle Wohnungen haben einen Freibereich.
        <br />
        <br />
        Mehrfamilienhaus München | Neubau | LPH 1-4, LPH 5-8 in Teilen | 2014-2017
    </Text>
    const city = "München"
    const country = "Deutschland"

    let type = ""
    if (projectID.includes("W")) {
        type = "wohnen"
    } else if (projectID.includes("OE")) {
        type = "oeffentlich"
    } else {
        type = "konzepte"
    }

    let imageList = [image1, image2, image3, image4, image5, image6, image7, image8, image9]

    /*
    for (let i = 2; i < imageLength + 1; i++) {
        let id
        if (i < 10) {
            id = `0${i}`
        } else {
            id = i.toString()
        }
        const image = require(`./${projectID}-${id}-${projectName}.webp`)
        imageList.push(image)
    } */

    return {
        name: name,
        notification: notification,
        image: window.innerWidth > 500 ? image1 : image0,
        images: imageList,
        text: text,
        year: projectID.split("-")[2],
        city: city,
        country: country,
        link: `/projekte/wohnen/mehrfamilienhaus-muenchen-neubau`,
        type: type,
    }
}